import OMSModule from "./liboms";

class OMS {
  constructor() {
    //this.onLibOMSReady = 0;
    this.liboms = 0;
    this.uint8Array = 0;
    this.heapBytes = 0;
    this.sequenceTable = 0;
    this.heapPtr = 0;

    this.sequence = 0;
    this.lastIndex = -1;

    this.onLibOMSReady = 0;

    this.onLibOMSInitialize = this.onLibOMSInitialize.bind(this);
    this.initialize         = this.initialize.bind(this);
    this._arrayToHeap       = this._arrayToHeap.bind(this);
    this._freeArray         = this._freeArray.bind(this);
    this.parseNext          = this.parseNext.bind(this);
    this.parse              = this.parse.bind(this);
    this.destroyOMS         = this.destroyOMS.bind(this);

    this.heapOffset = 0;

    this.initialized = false;
  }

  onLibOMSInitialize()
  {  
    if (this.onLibOMSReady != 0)
    {
      this.onLibOMSReady();
      this.initialized = true;
    }
    else{
      console.log("Failed to initialize");
    }
  }

  initialize()
  {
    let omsWrapper = this;
    OMSModule().then(async (result) => {
        
        omsWrapper.liboms = result;
        await omsWrapper.liboms.ready;
        omsWrapper.onLibOMSInitialize();
    });
  }

  clearOMS()
  {
    if (this.sequence !== 0){
      //Convert to null to mark TypedArrays for GC
      this.sequence.vertices = null;
      this.sequence.uvs = null;
      this.sequence.indices = null;
      this.sequence.bone_indices = null;
      this.sequence.bone_weights = null;

      for (let i = 0; i < this.sequence.ssdr_frames.length; i++){
          this.sequence.ssdr_frames[i] = null;
      }
    }

    if (this.heapPtr !== 0){
      this._freeArray();
      this.heapPtr = 0;
    }
  }

  _arrayToHeap(typedArray)
  {
    var numBytes = typedArray.length * typedArray.BYTES_PER_ELEMENT;

    if (this.liboms["asm"]["C"] === undefined){
        return false;
    }

    if (this.heapPtr === 0){
        this.heapPtr = this.liboms._malloc(numBytes);
        this.heapBytes = new Uint8Array(this.liboms.HEAPU8.buffer, this.heapPtr, numBytes);
    }
    else if (numBytes > this.heapBytes.length){
        this._freeArray();
        this.heapPtr = this.liboms._malloc(numBytes);
        this.heapBytes = new Uint8Array(this.liboms.HEAPU8.buffer, this.heapPtr, numBytes);
    }

    this.heapBytes.set(new Uint8Array(typedArray.buffer));
  }

  _freeArray()
  {
    this.liboms._free(this.heapPtr);
  }

  parseNext(sequenceIndex)
  {
    if (this.lastIndex === sequenceIndex){
        return this.sequence;
    }
    else if (this.sequence !== 0){
        //Convert to null to mark TypedArrays for GC
        this.sequence.vertices = null;
        this.sequence.uvs = null;
        this.sequence.normals = null;
        this.sequence.indices = null;
        this.sequence.bone_indices = null;
        this.sequence.bone_weights = null;

        for (let i = 0; i < this.sequence.ssdr_frames.length; i++){
            this.sequence.ssdr_frames[i] = null;
        }
    }

    this.lastIndex = sequenceIndex;
    let tableEntry = this.sequenceTable[sequenceIndex];

    if (!tableEntry){
        return undefined;
    }

      this.liboms.ccall('parseOMSSingle', 'number', ['number', 'number', 'number'], [this.heapOffset, this.uint8Array.length, tableEntry.start_byte]);

      var data = this.liboms.getSequence(sequenceIndex);

      var sequence =
      {
          // Vertices
          vertex_count: data.vertex_count,
          vertices: new Float32Array(this.liboms.HEAPF32.buffer, data.vertices, 3 * data.vertex_count),

          // UVs
          uv_count: data.uv_count,
          uvs: new Float32Array(this.liboms.HEAPF32.buffer, data.uvs, 2 * data.uv_count),

          // Normals
          normal_count: data.normal_count,
          normals: new Float32Array(this.liboms.HEAPF32.buffer, data.normals, 3 * data.normal_count),

          // Indices
          index_count: data.index_count,
          indices: new Uint16Array(),

          // SSDR Bones
          bone_indices: new Float32Array(4 * data.vertex_count),
          bone_weights: new Float32Array(4 * data.vertex_count),

          // SSDR
          ssdr_bone_count: data.ssdr_bone_count,
          ssdr_frame_count: data.ssdr_frame_count,

          ssdr_frames: []
      };

      if (sequence.vertex_count <= 65536)
      {
          sequence.indices = new Uint16Array(this.liboms.HEAPU16.buffer, data.indices, data.index_count);
      } 
      else {
          sequence.indices = new Uint32Array(this.liboms.HEAPU32.buffer, data.indices, data.index_count);
      }

      if (sequence.ssdr_frame_count > 1)
      {
          // Bones
          sequence.bone_indices = new Float32Array(this.liboms.HEAPF32.buffer, data.bone_indices, 4 * data.vertex_count);
          sequence.bone_weights = new Float32Array(this.liboms.HEAPF32.buffer, data.bone_weights, 4 * data.vertex_count);

          for (var n = 0; n < sequence.ssdr_frame_count; ++n)
          {
              var frameData = this.liboms.getSSDRFrame(n);
              sequence.ssdr_frames.push(new Float32Array(this.liboms.HEAPF32.buffer, frameData.matrices, 16 * sequence.ssdr_bone_count));
          }
      }

      this.sequence = sequence;
      return this.sequence;
    }

  parse(arrayBuffer)
  {
      // Convert to uint8_t array.
      this.uint8Array = new Uint8Array(arrayBuffer);
      this._arrayToHeap(this.uint8Array);

      this.lastIndex = -1;

      // HACK: Store heapOffset because it goes to zero after this call.
      this.heapOffset = this.heapBytes.byteOffset;

      // Parse via the c module.
      this.liboms.ccall('parseOMS', 'number', ['number', 'number'], [this.heapOffset, this.uint8Array.length]);
      var header = this.liboms.getHeader();

      // Parse header.
      var omsData =
      {
          version: header.version,
          sequence_count: header.sequence_count,
          has_retarget_data: header.has_retarget_data,
          compression_level: header.compression_level,
          frame_count: header.frame_count,

          sequenceTable: [],
          sequences: [],
      };

      for (let i = 0; i < header.sequence_count; i++){
          omsData.sequenceTable.push(this.liboms.getSequenceTableEntry(i));
      }

      this.sequenceTable = omsData.sequenceTable;

      return omsData;
  }

  destroyOMS = function() 
  {
      this.liboms = 0;
  }
}

export default OMS;