import ThreeWrapper from "./ThreeWrapper";
import RequestWorker from "./RequestWorker";
import PlayerUI from "./PlayerUI";
import VideoWorker from "./VideoWorker";
import VideoWorkerSoftware from "./VideoWorkerSoftware";
import VideoWorkerHLS from "./VideoWorkerHLS";
import OMSPlayer from "../omsplayer/omsplayer";
import Stats from "stats.js";

class HoloStream {
  constructor(options) {
    console.log(`HoloSuitePlayerWeb Version: ${HOLOSUITE_WEBPACK_BUILD}`);

    this.debugEnabled = true;
    if (options === undefined || options.debugEnabled === undefined || options.debugEnabled == false)
    {
        this.debugEnabled = false;
    }

    // Create a new HoloStream div, or attach to the specified one if provided
    this.containerDiv = undefined;
    this.createdContainerDiv = false;
    if (options && options.targetContainerDivID){
        this.containerDiv = document.getElementById(options.targetContainerDivID);
    }
    else{
        this.containerDiv = document.createElement("div");
        this.containerDiv.setAttribute("id", "holostreamContainer");
        this.containerDiv.setAttribute("style", "position: relative; width: 100%; height: 100%;");
        document.body.appendChild(this.containerDiv);
        this.createdContainerDiv = true;
    }

    // Create a new canvas to render on, or use the specified one if provided
    let holostreamCanvas = undefined;
    this.createdHolostreamCanvas = false;
    if (options && options.targetCanvasID){
        holostreamCanvas = document.getElementById(options.targetCanvasID);
    }
    else{
        holostreamCanvas = document.createElement("canvas");
        holostreamCanvas.setAttribute("id", "holostreamCanvas");
        holostreamCanvas.setAttribute("oncontextmenu", "return false;");
        holostreamCanvas.setAttribute("style", "width: 0; height: 0; position: absolute; left: 0; top: 0; z-index: 0;");
        this.containerDiv.appendChild(holostreamCanvas);

        this.createdHolostreamCanvas = true;
    }

    this.holostreamCanvas = holostreamCanvas;

    this.preUpdateFunctions = [];
    this.postUpdateFunctions = [];
    this.sequenceReadyFunctions = [];

    // Bind Functions
    this.openURL                = this.openURL.bind(this);
    this.update                 = this.update.bind(this);
    this.parsePlaybackSegment   = this.parsePlaybackSegment.bind(this);
    this.clearCurrentContent    = this.clearCurrentContent.bind(this);
    this.addToOMSBuffer         = this.addToOMSBuffer.bind(this);
    this.getQualityProfiles     = this.getQualityProfiles.bind(this);
    this.getActiveProfile       = this.getActiveProfile.bind(this);
    this.setQualityProfile      = this.setQualityProfile.bind(this);
    this.isMobileAppleDevice    = this.isMobileAppleDevice.bind(this);
    this.isAppleDevice          = this.isAppleDevice.bind(this);
    this.destroyHoloStream      = this.destroyHoloStream.bind(this);
    this.awaitClipCleanup       = this.awaitClipCleanup.bind(this);

    // Device specific configuration and rendering
    this.appleDevice = this.isAppleDevice();
    this.useSoftwareDecoding = false;

    // Events
    this.onURLOpened = 0;
    this.onQualityChanged = 0;

    // Timing information for the current clip. Assume 60/30, but get the correct values 
    // from the manifest to be sure we are correct.
    this.framesPerSegment = 60;
    this.framesPerSecond = 30;

    // Optional - Defines a minimum update interval in milliseconds between opening
    // MPDs. If a request is received within the interval, it is discarded.
    this.minimumOpenURLTime = 0;
    this.lastOpenURLTime = 0;

    // A semaphore for locking HoloStream down while cleaning up the current
    // MPD and opening a new one
    this.openURLLock = false;

    // Allows autoplay to begin once the current browser allows it, and once
    // the video element is ready to play. The user can hit the play button
    // before this state is reached.
    this.playedOnce = false;

    // Flag that is set when this HoloStream instance has already been used to load a clip
    this.clipIsLoaded = false;

    // Handle Events - Window
    this.handleResize = this.handleResize.bind(this);

    // Handle Events - OMS Player
    this.handleOMSPlayerReady   = this.handleOMSPlayerReady.bind(this);
    this.handleOMSPlaybackReady = this.handleOMSPlaybackReady.bind(this);
    this.handleKeyframeReady    = this.handleKeyframeReady.bind(this);
    this.handleFrameReady       = this.handleFrameReady.bind(this);

    // Handle Events - Request Worker
    this.handleQualityChanged = this.handleQualityChanged.bind(this);

    // Handles Events - Player UI
    this.handlePlay = this.handlePlay.bind(this);
    this.handleSeek = this.handleSeek.bind(this);

    // ThreeJS Renderer
    this.threeWrapper = new ThreeWrapper(holostreamCanvas, options);

    this.requestWorker = new RequestWorker();
    this.requestWorker.player = this.HoloStream;
    this.requestWorker.ui = this.playerUI;
    this.requestWorker.onQualityChanged = this.handleQualityChanged;

    let uiImages = (options === undefined || options.uiImages === undefined) ? {} : options.uiImages;
    // Player UI

    if (options === undefined || !options.hideUI){
        this.playerUI = new PlayerUI(uiImages, options.targetContainerDivID, options, this);
        this.playerUI.onPlay = this.handlePlay;
        this.playerUI.onSeek = this.handleSeek;
    }

    if (!this.appleDevice){   
        this.videoWorker = new VideoWorker(this.threeWrapper.getWebGLContext());
    }
    else{
        this.requestWorker.iOSPath = true;
        if (this.useSoftwareDecoding){
            this.videoWorker = new VideoWorkerSoftware();
        }
        else{
            this.videoWorker = new VideoWorkerHLS(this.threeWrapper.getWebGLContext());
        }
    }

    if (this.playerUI){
        this.playerUI.videoWorker = this.videoWorker;
    }

    this.videoWorker.debugEnabled = this.debugEnabled;

    // OMS Player
    this.omsPlayer = new OMSPlayer(this.handleOMSPlayerReady, false);
    this.omsPlayer.onPlaybackReady = this.handleOMSPlaybackReady;
    this.omsPlayer.onKeyframeReady = this.handleKeyframeReady;
    this.omsPlayer.onFrameReady    = this.handleFrameReady;

    // State
    this.omsBuffer = {};
    this.firstSegmentLoad = true;
    this.currentOMSSegment = 0;
    this.currentOMSFrame = 0;
    this.showingLoadingScreen = true;
    this.previousTime = Date.now();
    this.playing = false;
    this.firedSequenceReadyEvent = false;
    this.updatedVideoTexture = false;

    // On Desktop/Android, the first frame(s) can be black while the OMS data has been loaded
    // and the video data has not. It is not enough to check the videoplayer's ready state,
    // because when the video loops it will report an incorrect ready state on some platforms.
    // That can result in a texture desync on loop, and so this value is set to true once we have
    // begun playing the video, and know that our video is providing valid data we are able to
    // update our mesh.
    this.videoPlayerHasBeenReady = false;

    // Handle window resize
    window.addEventListener('resize', this.handleResize, false );

    // Debug Related
    if (this.debugEnabled)
    {
        this.stats = new Stats();
        this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild(this.stats.dom);
    }

    // Start
    this.threeWrapper.startRender();

    this.update();

    this.startTime = Date.now();
    this.lastTime = Date.now();

    // Prevents the update loop from being executed at a rate higher than 60fps. Without this in place, 
    // requestAnimationFrame will occasionally fire more often than necessary
    this.fps = 30;
    this.fpsInterval = 1000 / this.fps;
  }

  async awaitClipCleanup(url){
    this.openURLLock = false;

    if (this.playerUI && this.playing){
        this.playerUI.updatePlayState(true);
    }

    if (this.getThreeMesh().visible){
        this.threeWrapper.cachedVisibility = true;
        this.getThreeMesh().visible = false;
    }

    this.handlePlay(false);
    this.handleSeek(0);

    let delayPromise = new Promise(res => setTimeout(res, 100));
    await delayPromise;

    this.clipIsLoaded = false;
    this.openURL(url);
}

  async openURL(url)
  {
    if (this.openURLLock){
        return false;
    }

    // On non-webkit browsers, if a clip is already loaded, we need a moment to clean it up
    if (!this.isMobileAppleDevice()){
        if (this.videoWorker.videoPlayer.src && this.clipIsLoaded){
            this.awaitClipCleanup(url);
            return;
        }

        if (!this.clipIsLoaded){
            if (this.threeWrapper.cachedVisibility){
                this.getThreeMesh().visible = true;
            }
            this.clipIsLoaded = true;
        }
    }
    else{
        if (this.playerUI && this.playing){
            this.playerUI.updatePlayState(true);
        }
        this.handlePlay(false);
    }

    this.openURLLock = true;
    this.url = url;

    // Clear the VideoWorker and RequestWorker before opening a new MPD URL
    this.videoWorker.canUpdate = false;
    if (this.videoWorker.updating){
        let vidWorker = this.videoWorker;
        let checkUpdating = (resolve) => {
            if (vidWorker.updating){
                setTimeout(() => checkUpdating(resolve), 100);
                return;
            }
            resolve();
        };

        let updatingPromise = new Promise(resolve => {
            checkUpdating(resolve);
        });

        await updatingPromise;
    }

    if ((Date.now() - this.lastOpenURLTime) < this.minimumOpenURLTime){
        let delayPromise = new Promise(resolve => {
            setTimeout(resolve, this.minimumOpenURLTime);
        });

        await delayPromise;
    }

    await this.requestWorker.clearRequestWorker();

    this.clearCurrentContent();
    this.lastOpenURLTime = Date.now();
    this.playedOnce = false;
    this.videoWorker.canUpdate = true;
    this.openURLLock = false;

    // Reset stage.
    this.omsBuffer = {};
    this.firstSegmentLoad = true;
    this.currentOMSSegment = -1;
    this.currentOMSFrame = -1;
    this.showingLoadingScreen = true;
    this.firedSequenceReadyEvent = false;
    this.updatedVideoTexture = false;
    this.videoPlayerHasBeenReady = false;

    // Open the manifest.
    let openManifestSuccess = await this.requestWorker.openManifest(url);
    if (openManifestSuccess)
    {
        var profile = this.requestWorker.getActiveProfile();
        let audioSourceInfo = {};

        // iOS Path
        if (this.requestWorker.manifest.audioURL !== undefined){
            audioSourceInfo.src = this.requestWorker.manifest.url;
            audioSourceInfo.src = audioSourceInfo.src.replace("manifest.mpd", this.requestWorker.manifest.audioURL);
            audioSourceInfo.type = "audio/aac";
        }

        if (!this.useSoftwareDecoding){
            this.videoWorker.openVideo('video/mp4; codecs="' + profile.codecs + '"', this.requestWorker.getDuration(), 0, this.requestWorker.manifest.hlsURL);
        }
        else{
            this.videoWorker.openVideo('video/mp4; codecs="' + profile.codecs + '"', this.requestWorker.getDuration(), "omsAudio", profile, audioSourceInfo);
        }

        this.videoWorker.setFrameInformation(profile.duration, profile.timescale);
        
        if (this.playerUI){
            this.playerUI.resetLargePlayButtonStatus();
            this.playerUI.initializeLoadedSegmentDisplay(this.requestWorker.totalSegments);
        }

        if (this.onURLOpened !== 0)
        {
            this.onURLOpened(this.requestWorker.manifest);
        }

        return true;
    }

    return false;
  }

  update()
  {
    // Update UI
    if (this.playerUI){
        this.playerUI.isBuffering = this.videoWorker.isBuffering;
        this.playerUI.updatePlaybackPercentage(this.videoWorker.getPlaybackPercentage(), this.videoWorker.videoPlayer.currentTime);

        if (this.useSoftwareDecoding){
            this.playerUI.updateLoadedSegmentDisplay(this.requestWorker.totalSegmentsDownloaded, this.requestWorker.totalSegments);
        }
        else{
            // Don't include the initialization segment in the progress bar visualization
            this.playerUI.updateLoadedSegmentDisplay(this.requestWorker.totalSegmentsDownloaded - 1, this.requestWorker.totalSegments);
        }
    }

    // Do not run the update loop while we are awaiting on a new MPD
    if (this.openURLLock){
        requestAnimationFrame(this.update);
        return;
    }

    let now = Date.now();
    let elapsed = now - this.lastTime;

    if (elapsed > this.fpsInterval) {
        this.lastTime =  now - (elapsed % this.fpsInterval);
    }
    else{
        requestAnimationFrame(this.update);
        return;
    }

    this.preUpdateFunctions.forEach(func => {
        if (func.update){
            func.update();
        }
    });

    if (this.debugEnabled)
    {
        this.stats.begin();
    }

    if (this.omsPlayer.omsInstances.some(x => !x.initialized)){
        requestAnimationFrame(this.update);
        return;
    }

    let activeProfile = this.requestWorker.getActiveProfile();
    
    if (activeProfile !== undefined){
        this.framesPerSegment = activeProfile.duration;
        this.framesPerSecond = activeProfile.timescale;
    }

    let frame = Math.floor(this.videoWorker.videoPlayer.currentTime * this.framesPerSecond);
    let segment =  Math.floor(frame / this.framesPerSegment);

    // HACK for Firefox part 1: Once the player is no longer at the beginning of
    // the video, allow HoloStream to force a loop
    if (this.videoWorker.videoPlayer.currentTime > 1){
        this.canForceLoop = true;
    }

    // HACK for Firefox part 2: Firefox will clear SourceBuffer contents
    // automatically. It does this after 29 segments are added to the buffer. If
    // this occurs right before we loop (eg. in a 29 segment sequence) then the buffered
    // data will be cleared before it is played. This causes the video player to report
    // that it's 'currentTime' is 0, but the playhead is still stuck at the end of the sequence,
    // resulting in a freeze and texture desync.
    //
    // If we detect that we are at the start of the sequence, and also do not have the buffered data
    // available, then we immediately reset the RequestWorker's segmentIndex to get and apply the
    // required video data. This is not necessary for seeking; this only occurs on a "bad loop".
    if (frame === 0 && segment === 0 && this.canForceLoop){
        if (!this.videoWorker.hasBufferedSegmentForCurrentTime()){
            this.requestWorker.segmentIndex = 0;

        }
        this.videoWorker.seekTo(0);
        this.canForceLoop = false;
    }
 
    // Update the workers.
    this.requestWorker.setLastPlayedSegment(this.videoWorker.getCurrentSegment());
    this.requestWorker.update();
    this.videoWorker.update(frame, segment);

    if (activeProfile === undefined)
    {
        requestAnimationFrame(this.update);
        return;
    }

    // Get mesh texture webgl handle from renderer.
    // Will be undefined if the texture hasn't populated yet.
    var meshTexture = undefined;
    if (this.useSoftwareDecoding){
        meshTexture = this.threeWrapper.getMeshTexture(this.videoWorker.textureWidth, this.videoWorker.textureHeight);
    }
    else{
        meshTexture = this.threeWrapper.getMeshTexture(activeProfile.width, activeProfile.height);
    }

    let updatedVideoTextureThisFrame = false;
    // See if segment data is ready for moving.
    if (this.videoWorker.isReady())
    {
        if (!this.updatedVideoTexture && meshTexture){
            this.videoWorker.updateVideoTexture(meshTexture, frame, segment);
            updatedVideoTextureThisFrame = true;
            this.updatedVideoTexture = true;
        }

        var playbackSegment = this.requestWorker.getNextPlaybackSegment();
        // Do not parse an undefined or stale segment. Only parse if we have updated the video texture.
        if (playbackSegment !== undefined && playbackSegment.downloadMarker === this.requestWorker.activeDownloadMarker && this.updatedVideoTexture)
        {
            this.parsePlaybackSegment(playbackSegment);
        }
    }

    if (this.videoWorker.videoPlayer.readyState >= 2){
        this.videoPlayerHasBeenReady = true;
    }

    // Update OMS and Texture
    // Note: if the video worker is currently seeking the texture copy
    // and decode is unreliable.
    if (!this.videoWorker.isSeeking && this.videoWorker.isReady() && !this.firstSegmentLoad && meshTexture !== undefined)
    {
        this.videoWorker.omsBuffer = Object.keys(this.omsBuffer);

        if (!updatedVideoTextureThisFrame){
            this.videoWorker.updateVideoTexture(meshTexture, frame, segment);
        }

        this.requestWorker.setLastPlayedSegment(this.videoWorker.getCurrentSegment());

        var validSegment = true;
        var currentVideoSegment = Math.floor(this.videoWorker.decodedFrameIndex / this.framesPerSegment) + 1;
        if (this.useSoftwareDecoding || this.appleDevice){
            currentVideoSegment = Math.floor(this.videoWorker.decodedFrameIndex / this.framesPerSegment);
        }

        if (this.playing && !this.playedOnce){
            this.playedOnce = true;
            this.videoWorker.play();
        }

        if (currentVideoSegment != this.currentOMSSegment)
        {
            this.currentOMSSegment = currentVideoSegment;
            if (this.omsBuffer[this.currentOMSSegment])
            {
                this.omsPlayer.clearCachedData();
                this.omsPlayer.loadOMSData(this.omsBuffer[this.currentOMSSegment], 0);
            } 
            else 
            {
                // If the current segment available we should not update anything
                // else and just leave the texture as is to prevent mismatches.
                validSegment = false;
            }
        }

        // Update OMS frame
        if (validSegment)
        {
            var omsFrameIndex = this.videoWorker.decodedFrameIndex % this.framesPerSegment;
            if (omsFrameIndex != this.currentOMSFrame && this.videoPlayerHasBeenReady)
            {
                this.omsPlayer.loadFrame(omsFrameIndex);
                this.currentOMSFrame = omsFrameIndex;
            }
        }
    }

    // Hide loading screen if everything is ready for playback.
    if (meshTexture !== undefined && this.videoWorker.canPlayFromCurrentTime() && this.requestWorker.canPlayFromSegment(0) && !this.videoWorker.isBuffering)
    {
        // External event API users can hook into.
        if (!this.firedSequenceReadyEvent){
            this.firedSequenceReadyEvent = true;
            if (this.playerUI){
                this.playerUI.showLargePlayButton();   
            }
            this.sequenceReadyFunctions.forEach(func => {
                if (func.update){
                    func.update();
                }
            });
        }

        if (this.showingLoadingScreen){
            this.showingLoadingScreen = false;
        }
    }

    // Render.
    this.threeWrapper.render();

    if (this.playerUI){
        this.playerUI.update();
        
        // Note(rbrt): THREE can resize the canvas without triggering a resize event on startup,
        // and afaict there's no THREE event to hook into for this. Monitor the width of the canvas
        // and it's parent, and change accordingly
        if (this.threeWrapper.threeCanvas.clientWidth !== this.threeWrapper.canvasParent.offsetWidth ||
            this.threeWrapper.threeCanvas.clientHeight !== this.threeWrapper.canvasParent.offsetHeight){
            this.handleResize();
        }
    }

    if (this.debugEnabled)
    {
        this.stats.end();
    }

    this.postUpdateFunctions.forEach(func => {
        if (func.update){
            func.update();
        }
    });

    requestAnimationFrame( this.update );
  }

  getQualityProfiles()
  {
    return this.requestWorker.manifest.profiles;
  }

  getActiveProfile()
  {
    var profiles = this.getQualityProfiles();
    return profiles[this.requestWorker.activeProfile];
  }

  setQualityProfile(profile)
  {
    // Switch to adaptive
    if (profile === undefined)
    {
        var activeProfile = this.requestWorker.activeProfile;
        this.requestWorker.setActiveProfile(activeProfile, true);
        return;
    }

    // Otherwise turn off adaptive and select the profile.
    var profiles = this.getQualityProfiles();
    for(var i = 0; i < profiles.length; ++i)
    {
        if (profiles[i] === profile)
        {
            // Disable adaptive if we've selected a profile at this level.
            this.requestWorker.setActiveProfile(i, false);
            return;
        }
    }
  }

  handleResize()
  {
    this.threeWrapper.onResize();
  }

  handleOMSPlayerReady()
  {
    //console.log("OMS Player Ready.");
  }

  handleOMSPlaybackReady()
  {
    //console.log("OMS Playback Ready.");
  }

  handleKeyframeReady(sequence)
  {
    //console.log("KEY FRAME READY.");
    this.threeWrapper.onKeyframeReady(sequence);
  }

  handleFrameReady(sequence, boneMatrices, textureData)
  {
    //console.log("FRAME READY.");
    this.threeWrapper.onFrameReady(sequence, boneMatrices, textureData);
  }

  handleQualityChanged(profile)
  {
    if (this.onQualityChanged !== 0)
    {
        this.onQualityChanged(profile);
    }
  }

  handlePlay(playing)
  {
    if (playing)
    {
        this.playing = true;
        this.videoWorker.play();
    } 
    else {
        this.playing = false;
        this.videoWorker.pause();
    }
  }

  handleSeek(value)
  {
    let percentage = value / this.videoWorker.videoPlayer.duration;
    this.videoWorker.seekTo(percentage);
    this.requestWorker.seekTo(percentage);

    if (this.useSoftwareDecoding){
        this.showingLoadingScreen = true;

        if (this.playerUI){
            this.playerUI.showLoadingScreen();
        }
    }
  }

  clearCurrentContent()
  {
    this.omsPlayer.clearOMSPlayer();

    this.activeFrame  = 0;
    this.activeChunk = 0;

    let emptySequence = {
        vertices: [],
        uvs: [],
        bone_indices: [],
        bone_weights: [],
        indices: []
    };
    this.handleKeyframeReady(emptySequence);

    this.videoWorker.clearWorker();
  }

  addToOMSBuffer(index, data)
  {
    this.omsBuffer[index] = data;
  }

  parsePlaybackSegment = async function(playbackSegment)
  {
    let parsedData = await playbackSegment.data;
    // Do not parse the segment if it became stale while we awaited on the download
    if (playbackSegment.downloadMarker !== this.requestWorker.activeDownloadMarker){
        return;
    }

    if (!this.useSoftwareDecoding){
        if (playbackSegment.url.endsWith(".m4s")){
            let m4sData = 0;
            let omsData = 0;
            
            let dataView = new DataView(parsedData);
            let index = 0;
            for(; index < parsedData.byteLength; ) {
                let atomSize = dataView.getUint32(index, false);

                let nextValueA = dataView.getUint8(index + 4);
                let nextValueB = dataView.getUint8(index + 5);
                let nextValueC = dataView.getUint8(index + 6);
                let nextValueD = dataView.getUint8(index + 7);

                let values = [nextValueA, nextValueB, nextValueC, nextValueD];

                let first = String.fromCharCode(nextValueA);
                let second = String.fromCharCode(nextValueB);
                let third = String.fromCharCode(nextValueC);
                let fourth = String.fromCharCode(nextValueD);

                if (first === "o" && second === "m" && third === "s" && fourth === "d"){
                    omsData = parsedData.slice(index + 8, parsedData.byteLength);
                    m4sData = parsedData.slice(0, index);
                    break;
                }

                if (atomSize === 0){
                    break;
                }

                index += atomSize;
            }
            if (m4sData !== 0){
                this.addToOMSBuffer(playbackSegment.segment.index, omsData);
                this.videoWorker.addToVideoBuffer(playbackSegment.segment.index, m4sData, playbackSegment.segment.url);

                // Segments can arrive out of order; ensure that we load the first segment before any others.
                // This avoids the case that we begin playback with an empty OMS buffer for the first segment.
                if (this.firstSegmentLoad && playbackSegment.segment.index === 1)
                {
                    this.omsPlayer.loadOMSData(this.omsBuffer[1], 0);
                    this.firstSegmentLoad = false;
                }
            }

            parsedData = null;
        } 
        else if (playbackSegment.url.endsWith(".oms")){
            this.addToOMSBuffer(playbackSegment.segment.index, parsedData);
            
            if (this.firstSegmentLoad){
                this.videoWorker.setTotalSegments(this.requestWorker.getActiveProfile().segments.length);
                this.omsPlayer.loadOMSData(this.omsBuffer[0], 0);
                this.omsPlayer.loadFrame(0, 0, true);
                this.firstSegmentLoad = false;
            }
            else{
                if (this.debugEnabled){
                    console.log("Did not load a segment. Added segment to buffer: " + playbackSegment.segment.index);
                }
            }

            parsedData = null;
        }
        else {
            this.videoWorker.setTotalSegments(this.requestWorker.getActiveProfile().segments.length);

            // Initialization segment.
            this.videoWorker.addToVideoBuffer(0, parsedData, playbackSegment.segment.url);

            parsedData = null;
        }
    }
    else{
        if (this.firstSegmentLoad){
            this.videoWorker.setTotalSegments(this.requestWorker.getActiveProfile().segments.length);
        }

        let mp4Data = 0;
        let omsData = 0;

        let dataView = new DataView(parsedData);
        let index = 0;
        for(; index < playbackSegment.data.byteLength; ) {
            let atomSize = dataView.getUint32(index, false);

            let nextValueA = dataView.getUint8(index + 4);
            let nextValueB = dataView.getUint8(index + 5);
            let nextValueC = dataView.getUint8(index + 6);
            let nextValueD = dataView.getUint8(index + 7);

            let values = [nextValueA, nextValueB, nextValueC, nextValueD];

            let first = String.fromCharCode(nextValueA);
            let second = String.fromCharCode(nextValueB);
            let third = String.fromCharCode(nextValueC);
            let fourth = String.fromCharCode(nextValueD);

            if (first === "o" && second === "m" && third === "s" && fourth === "d"){
                omsData = playbackSegment.data.slice(index + 8, playbackSegment.data.byteLength);
                mp4Data = playbackSegment.data.slice(0, index);
                break;
            }

            if (atomSize === 0){
                break;
            }

            index += atomSize;
        }

        if (mp4Data !== 0){

            this.addToOMSBuffer(playbackSegment.segment.index, omsData);
            this.videoWorker.addToVideoBuffer(playbackSegment.segment.index, mp4Data, {width: playbackSegment.segment.width, height: playbackSegment.segment.height});

            // First time loading an OMS segment.
            if (this.firstSegmentLoad){
                this.omsPlayer.loadOMSData(this.omsBuffer[0], 0);
                this.omsPlayer.loadFrame(0, 0, true);
                this.firstSegmentLoad = false;
            }
        }

        parsedData = null;
    }   
  }

  async showVideoPlayer(){
    while (!this.videoWorker || !this.videoWorker.videoPlayer){
        let delayPromise = new Promise(resolve => {
            setTimeout(resolve, this.minimumOpenURLTime);
        });

        await delayPromise;
    }

    this.videoWorker.videoPlayer.width = 512;
    this.videoWorker.videoPlayer.height = 512;
    this.videoWorker.videoPlayer.style.zIndex = 9999;
  }

  isMobileAppleDevice(){
    return [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  isAppleDevice(){
    // Detect iPhone or iPad
    let isMobileAppleDevice = this.isMobileAppleDevice();
    let isSafari = navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome");
    
    let debugPath = false;

    if (isMobileAppleDevice || isSafari || debugPath){
        return true;
    }

    return false;
  }

  destroyHoloStream() {
    this.openURLLock = true;

    // Only destroy HoloStream related div elements if HoloStream created them.
    if (this.createdContainerDiv){
        this.containerDiv.parentNode.removeChild(this.containerDiv);
    }
    
    if (this.createdHolostreamCanvas){
        this.holostreamCanvas.parentNode.removeChild(this.holostreamCanvas);
    }

    this.omsBuffer = null;

    this.videoWorker.destroy();
    this.videoWorker = null;

    this.omsPlayer.destroy();
    this.omsPlayer = null;

    if (this.playerUI){
        this.playerUI.destroy();
        this.playerUI = null;
    }

    this.threeWrapper.destroy();
    this.threeWrapper = null;

    this.requestWorker.destroy();
    this.requestWorker = null;
  }

  getHoloStreamCanvas() {
    return this.holostreamCanvas;
  }

  getThreeScene() {
    return this.threeWrapper.getThreeScene();
  }

  getThreeMesh(){
    return this.threeWrapper.getThreeMesh();   
  }

  getThreeRenderer(){
    return this.threeWrapper.getThreeRenderer();   
  }

  getThreeCamera(){
    return this.threeWrapper.getThreeCamera();   
  }

  addDefaultEventHandler(eventHandler){
    this.playerUI.ui.defaultEventListener = eventHandler; 
  }

  getLightingEnabled(){
    return this.threeWrapper.getLightingEnabled();
  }

  setLightingEnabled(enabled){
    this.threeWrapper.setLightingEnabled(enabled);
  }

  setOrbitControls(controls){
    this.playerUI.setOrbitControls(controls);
  }
}

export default HoloStream;