import OMSPlayer from "./omsplayer/omsplayer";
import HoloStream from "./holostream/HoloStream";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export { OMSPlayer, HoloStream };

// For Backward Compatibility:
//  The old plugin exposed HoloStream in global scope so we do the same
//  to allow legacy projects to easily update.
//  The old plugin also bundled ThreeJS OrbitControls :(

if (window)
{
    window.HoloStream = HoloStream;

    // If the player didn't import threejs already we can inject our copy to spare
    // downloading it twice.
    if (!window.THREE)
    {
        window.THREE = THREE;
    }

    if (window.THREE)
    {
        window.THREE.OrbitControls = OrbitControls;
    }
}