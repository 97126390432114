import { Broadway } from "./broadway-master/Player/mp4";
import holostreamConfiguration from "./holostream-config";

class VideoWorkerSoftware {
  constructor() {
    // Bind Functions
    this.openVideo              = this.openVideo.bind(this);
    this.update                 = this.update.bind(this);
    this.isReady                = this.isReady.bind(this);
    this.addToVideoBuffer       = this.addToVideoBuffer.bind(this);
    this.play                   = this.play.bind(this);
    this.pause                  = this.pause.bind(this);
    this.seekTo                 = this.seekTo.bind(this);
    this.setTotalSegments       = this.setTotalSegments.bind(this);
    this.getCurrentSegment      = this.getCurrentSegment.bind(this);
    this.getPlaybackPercentage  = this.getPlaybackPercentage.bind(this);
    this.updateVideoTexture     = this.updateVideoTexture.bind(this);
    this.processBufferData      = this.processBufferData.bind(this);
    this.clearWorker            = this.clearWorker.bind(this);
    this.processData            = this.processData.bind(this);
    this.canPlayFromCurrentTime = this.canPlayFromCurrentTime.bind(this);

    // Internal Events
    this.onVideoPlayerError         = this.onVideoPlayerError.bind(this);
    this.onVideoPlayerCanPlay       = this.onVideoPlayerCanPlay.bind(this);
    this.onSourceBufferUpdateEnd    = this.onSourceBufferUpdateEnd.bind(this);
    this.onSourceBufferError        = this.onSourceBufferError.bind(this);

    // Playback Management
    this.previousVideoPlayerTime = 0.0;
    this.isPlaying = false;
    this.isSeeking = false;

    // Frame Decoding
    this.frameDecodeCanvas  = 0;
    this.frameDecodeContext = 0;
    this.decodedFrameIndex  = 0;

    // Video Management
    this.loadedVideo = false;
    this.videoPlayerCanPlay = false;
    this.videoPlayer = 0;
    this.mimeCodec = "";
    this.duration = 0;
    this.mediaSourceOpen = false;   
    this.sourceBuffer = 0;
    this.videoDataBuffer = [];
    this.sourceBufferReady = false;
    this.lastAddedVideoIndex = 0;
    this.totalSegments = 0;
    this.framesPerSegment = 0;
    this.framesPerSecond = 0;

    this.textureWidth = 0;
    this.textureHeight = 0;
    this.broadwayInstances = [];
    this.broadwayInstanceCount = 1;
    this.activeBroadwayInstance = 0;
    this.textureData = 0;

    this.cachedFrames = [];
    this.playedSegments = [];

    this.autoPaused = false;
    this.lookAheadSegmentCount = 1;
  }

  onVideoPlayerError(err)
  {
      console.log("Video Player Error: ");
      console.log(err);
  }

  onVideoPlayerCanPlay()
  {
      this.videoPlayerCanPlay = true;
      this.isSeeking = false;
  }

  clearWorker()
  {
    for (let i = 0; i < this.broadwayInstanceCount; i++){
        if (this.broadwayInstances[i] && this.broadwayInstances[i].player && this.broadwayInstances[i].player.avc.worker){
            this.broadwayInstances[i].player.avc.worker.terminate();
        }
        if (this.broadwayInstances[i]){
            this.broadwayInstances[i].lockVideoProcessing = false;
            this.broadwayInstances[i].player.avc.chunk = undefined;
        }
    }

    this.videoPlayerCanPlay = false;
    this.videoDataBuffer = [];
  }

  canPlayFromCurrentTime(frame, segment){
    //let frameInSegment = frame % this.framesPerSegment;

    if (this.cachedFrames[segment] === undefined || this.cachedFrames[segment] === null || this.cachedFrames[segment].cachedFrames === null){
        //console.log("Cached frames were null or undefined for segment " + segment + " and frame is " + frame);
        return false;
    }

    let lookAheadSegment = (segment + 1) % this.cachedFrames.length;
    for (let i = 0; i < this.lookAheadSegmentCount; i++){

        if (this.cachedFrames[lookAheadSegment] === null){
            //console.log(lookAheadSegment + " is null and segment is " + segment);
            return false;
        }

        if (this.cachedFrames[lookAheadSegment].cachedFrames === null){
            //console.log(lookAheadSegment + " is null and segment is " + segment);
            return false;
        }

        if (this.cachedFrames[lookAheadSegment].cachedFrames.some(x => x === undefined || x === null)){
            //console.log(lookAheadSegment + " contains null or undefined and segment is " + segment);
            return false;
        }

        if (this.cachedFrames[lookAheadSegment].cachedFrames.length === 0){
            //console.log(lookAheadSegment + " is length 0 and segment is " + segment);
        }

        lookAheadSegment = (lookAheadSegment + 1) % this.cachedFrames.length;
    }

    return true;
  }

  openVideo(mimeCodec, duration, audioElementId = 0, profile, audioSourceInfo = {src:"", type:""})
  {
    this.textureWidth = profile.width;
    this.textureHeight = profile.height;

    // Reset state
    this.loadedVideo = false;
    this.videoPlayerCanPlay = false;
    this.mediaSource = 0;
    this.mimeCodec = "";
    this.duration = duration;
    this.mediaSourceOpen = false;   
    this.sourceBuffer = 0;
    this.videoDataBuffer = [];
    this.sourceBufferReady = false;
    this.lastAddedVideoIndex = 0;
    this.totalSegments = 0; 

    // Check if an audioElementId was provided, if not create one.
    if (this.videoPlayer === 0)
    {
        if (audioElementId !== 0)
        {
            this.videoPlayer = document.getElementById(audioElementId);  
        } 

        if (this.videoPlayer === undefined || this.videoPlayer === null){
            this.videoPlayer = document.createElement("video");
            this.videoPlayer.setAttribute("id", "videoPlayer");
            this.videoPlayer.setAttribute("width", "1");
            this.videoPlayer.setAttribute("height", "1");
            this.videoPlayer.setAttribute("style", "position: absolute;");
            this.videoPlayer.setAttribute("playsinline", null);
            this.videoPlayer.setAttribute("loop", null);
            document.body.appendChild(this.videoPlayer);
        }
    }

    if (this.broadwayInstances.length === 0){
        for (let i = 0; i < this.broadwayInstanceCount; i++){
            let broadwayNode = document.createElement("div");
            broadwayNode.setAttribute("id", "broadway");
            broadwayNode.setAttribute("class", "broadway");
            broadwayNode.setAttribute("width", "1");
            broadwayNode.setAttribute("height", "1");
            broadwayNode.setAttribute("workers", "true");
            broadwayNode.setAttribute("render", "false");
            broadwayNode.setAttribute("webgl", "true");

            this.broadwayInstances.push(new Broadway(broadwayNode));
        }
    }
    else{
        for (let i = 0; i < this.broadwayInstanceCount; i++){
            this.broadwayInstances[i].workers.forEach(worker => worker.hasHandler = false);
            this.broadwayInstances[i].workers.forEach(worker => worker.terminate());
            this.broadwayInstances[i].workers = [new Worker(holostreamConfiguration.decoderLocation)];
            this.broadwayInstances[i].player = undefined;
        }
    }

    this.mimeCodec = mimeCodec;
    this.duration = duration;

    // Setup Video Player
    this.videoPlayer.addEventListener('error', this.onVideoPlayerError);

    this.videoPlayer.src = audioSourceInfo.src;
    this.videoPlayer.setAttribute("type", audioSourceInfo.type);
    this.videoPlayer.currentTime = 0.0;
    this.loadedVideo = true;

    this.cachedFrames = [];
    this.lastSegmentToFree = undefined;
    this.previousFrame = undefined;
  }

  async processData(buffer, cachedFrames)
  {
    let broadwayInstance = this.broadwayInstances[this.activeBroadwayInstance];
    let instanceIndex = this.activeBroadwayInstance;
    
    let startTime = Date.now();

    let broadwayPromise = new Promise((resolve, reject) => {
        broadwayInstance.loadNewVideo(buffer.videoData, cachedFrames[buffer.index], buffer.index, resolve, {offset: this.activeBroadwayInstance, total: this.broadwayInstanceCount, startTime: startTime});
    });

    await broadwayPromise;

    buffer.videoData = null;

    let endTime = Date.now();
    this.broadwayInstances[instanceIndex].lockVideoProcessing = false;

    if (cachedFrames[buffer.index] && cachedFrames[buffer.index].cachedFrames.every(x => x !== undefined)){
        //this.broadwayInstances[instanceIndex].lockVideoProcessing = false;
    }
    else{
        console.log("Did not unlock broadway instance " + instanceIndex + " which processed " + buffer.index);
        if (cachedFrames[buffer.index]){
            console.log(cachedFrames[buffer.index].cachedFrames);
        }
        else{
            console.log(cachedFrames);
            console.log("No cached frames for " + buffer.index);
        }
    }
  }

  update(frame, segment)
  {
    if (!this.loadedVideo){
        return;
    }

    if (!this.canPlayFromCurrentTime(frame, segment)){
        // Pause if we don't have enough to play
        if (this.isPlaying){
            this.autoPaused = true;
            this.lookAheadSegmentCount = 3;
            this.pause();
        }
    }
    else{
        // Unpause if we paused because of not having enough content cached
        if (!this.isPlaying && this.autoPaused){
            this.lookAheadSegmentCount = 1;
            this.play();
            return;
        }

        // Fire the video player ready event if we have enough content, and we have not already 
        // fired the event
        if (!this.videoPlayerCanPlay){
            this.onVideoPlayerCanPlay();
        }
    }

    let debugString = "";
    for (let i = 0; i < this.broadwayInstances.length; i++){
        debugString += i + ": " + this.broadwayInstances[i].lockVideoProcessing;
    }

    debugString += " -- Queue Size: " + this.videoDataBuffer.length;

    if (this.videoDataBuffer.length > 0 && this.cachedFrames.length > 0 && !this.broadwayInstances[this.activeBroadwayInstance].lockVideoProcessing){
        let buffer = this.videoDataBuffer.shift();
        let cachedFrames = this.cachedFrames;

        if (cachedFrames[buffer.index] === null || cachedFrames[buffer.index].cachedFrames === null || cachedFrames[buffer.index].cachedFrames.some(x => x === undefined)){
            cachedFrames[buffer.index] = {cachedFrames: []};

            this.broadwayInstances[this.activeBroadwayInstance].lockVideoProcessing = true;
            cachedFrames[buffer.index].dimensions = buffer.dimensions;
            this.processData(buffer, cachedFrames);

            this.activeBroadwayInstance = (this.activeBroadwayInstance + 1) % this.broadwayInstanceCount;
        }
    }

    // Check playback to see if we hit the end of the source buffer.
    if (this.isPlaying){
        let currentVideoSegment = this.getCurrentSegment();
        if (this.videoPlayer.currentTime == this.previousVideoPlayerTime && currentVideoSegment >= (this.totalSegments - 1)){
            this.videoPlayer.currentTime = 0.0;
        }
        this.previousVideoPlayerTime = this.videoPlayer.currentTime;
    }

    if (this.cachedFrames[this.lastSegment] === null){
        return;
    }

    if(this.lastSegment !== segment && 
       this.cachedFrames[this.lastSegment] !== null && 
       this.cachedFrames[this.lastSegment] !== undefined && 
       this.cachedFrames[this.lastSegment].cachedFrames !== null && 
       this.cachedFrames[this.lastSegment] !== undefined){
        // Hack: don't free frames if the sequence is small enough
        // Don't free frames if we're not currently playing
        if (this.cachedFrames.length > 4 && !this.isPlaying && this.videoPlayerCanPlay && segment !== this.lastSegment){
            let segmentDistance = 0;
            let freeResource = true;
            for (let i = 1; i <= 2; i++){
                let checkIndex = (this.lastSegment + i) % this.cachedFrames.length;
                if (checkIndex === segment){
                    freeResource = false;
                    break;
                }
            }

            if (freeResource && this.lastSegment < segment){
                for (let i = 0; i < this.cachedFrames[this.lastSegment].cachedFrames.length; i++){
                    this.cachedFrames[this.lastSegment].cachedFrames[i] = null;
                }
                this.cachedFrames[this.lastSegment] = null;
                console.log("Freed " + this.lastSegment + " and current segment is " + segment + " and frame is " + frame);
                this.lastSegment = segment;
            }
        }
    }
    this.lastSegment = segment;
  }

  isReady()
  {
    if (this.broadwayInstances.length > 0)
    {
        return true;
    }

    return false;
  }

  addToVideoBuffer(index, data, dimensions)
  {
    for (let i = 0; i < this.videoDataBuffer.length; i++){
        if (this.videoDataBuffer[i].index === index){
            this.videoDataBuffer[i].videoData = data;
            this.videoDataBuffer[i].dimensions = dimensions;
            return;
        }
    }

    this.videoDataBuffer.push({
        index: index,
        videoData: data,
        dimensions: dimensions
    });
  }

  processBufferData(index, data){

  }

  onSourceBufferUpdateEnd(event)
  {
    this.sourceBufferReady = true;
  }

  onSourceBufferError(event)
  {
    console.log("Source Buffer Error: ");
    console.log(event);
  }

  play()
  {
    this.autoPaused = false;
    this.isPlaying = true;
    this.videoPlayer.play();
  }

  pause()
  {
    this.isPlaying = false;
    this.videoPlayer.pause();
  }

  seekTo(progress)
  {
    this.clearWorker();
    this.videoPlayer.currentTime = this.videoPlayer.duration * progress;
  }

  setTotalSegments(count)
  {
    this.totalSegments = count;
    this.cachedFrames = [];
    for (let i = 0; i < count; i++){
        this.cachedFrames.push({cachedFrames: null});
    }
  }

  getCurrentSegment()
  {
    return Math.floor(this.videoPlayer.currentTime / this.videoPlayer.duration * this.totalSegments);
  }

  getPlaybackPercentage()
  {
    return this.videoPlayer.currentTime / this.videoPlayer.duration;
  }

  updateVideoTexture(textureObject, frame, segment)
  {
    if (this.lastSegment === undefined){
        this.lastSegment = segment;
    }

    let frameInSegment = frame % this.framesPerSegment;

    if (this.cachedFrames[segment] === undefined || this.cachedFrames[segment] === null || this.cachedFrames[segment].cachedFrames === null || this.cachedFrames[segment].cachedFrames[frameInSegment] === undefined){
        return;
    }

    let textureData = this.cachedFrames[segment].cachedFrames[frameInSegment].data;

    if (textureData === undefined){
        return;
    }

    let width = parseInt(this.cachedFrames[segment].cachedFrames[frameInSegment].dimensions.width);
    let height = parseInt(this.cachedFrames[segment].cachedFrames[frameInSegment].dimensions.height);

    this.textureWidth = width;
    this.textureHeight = height;

    var ylen = width * height;
    var uvlen = (width / 2) * (height / 2);

    textureObject.textures[0].image.data = textureData.subarray(0, ylen);
    textureObject.textures[1].image.data = textureData.subarray(ylen, ylen + uvlen);
    textureObject.textures[2].image.data = textureData.subarray(ylen + uvlen, ylen + uvlen + uvlen);
    textureObject.textures[0].needsUpdate = true;
    textureObject.textures[1].needsUpdate = true;
    textureObject.textures[2].needsUpdate = true;

    if (this.decodedFrameIndex !== frame){
        // Hack: don't free frames if the sequence is small enough
        if (this.cachedFrames.length > 4){

            if (!this.canPlayFromCurrentTime(frame, segment)){
                this.decodedFrameIndex = frame;
                return;
            }

            let previousFrame = this.decodedFrameIndex % this.framesPerSegment;

            if (this.previousFrame !== undefined && this.lastSegmentToFree !== undefined){
                if (previousFrame !== this.previousFrame){
                    this.cachedFrames[this.lastSegmentToFree].cachedFrames[previousFrame] = undefined;
                }
                else{
                    console.log("Would've freed a BAD FRAME");
                }
            }
            this.previousFrame = previousFrame;
            this.lastSegmentToFree = segment;
        }
    }

    this.decodedFrameIndex = frame;
  }

  setFrameInformation = function(framesPerSegment, framesPerSecond)
  {
    this.framesPerSegment = framesPerSegment;
    this.framesPerSecond = framesPerSecond;
  }
}

export default VideoWorkerSoftware;