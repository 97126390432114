import holostreamConfiguration from "./holostream-config";

class PlayerUI {
  constructor(uiImages, uiContainerDivID, options, holoStream) {
    this.holoStream = holoStream;
    this.optionsModalOpen = false;

    this.isReady = this.isReady.bind(this);
    this.update = this.update.bind(this);
    
    this.updateBufferPercentage = this.updateBufferPercentage.bind(this);
    this.updatePlayState = this.updatePlayState.bind(this);
    this.initializeLoadedSegmentDisplay = this.initializeLoadedSegmentDisplay.bind(this);
    this.constructDefaultUI = this.constructDefaultUI.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleIconVisibility = this.toggleIconVisibility.bind(this);
    this.onClickLightingToggle = this.onClickLightingToggle.bind(this);
    this.onClickOrbitToggle = this.onClickOrbitToggle.bind(this);

    this.updateProgressStyle = this.updateProgressStyle.bind(this);
    this.destroy = this.destroy.bind(this);

    this.onPlay = 0;
    this.onSeek = 0;

    // Define UI Colors
    this.bufferedColor = "#bde5ff";
    this.unbufferedColor = "#555";
    this.playbackHeadColor = "#79bdfc";

    this.bufferedPercentage = 0;
    this.playbackPercentage = 0;
    this.bufferingSpinner = 0;

    this.loadedSegments = [];
    this.splashLoadedSegments = [];

    this.playing = false;

    this.uiLoaded = false;
    this.uiContainerDivID = uiContainerDivID;

    // Load UI Images
    uiImages = this.preprocessUIImages(uiImages);
    this.uiImages = uiImages;

    this.controlHeight = 18;

    this.isLoadingScreenVisible = true;
    this.isBuffering = false;

    this.lightingEnabledStartup = options.lightingEnabled;
    this.constructDefaultUI(document.getElementById(this.uiContainerDivID));

    const volumeControl = document.getElementById("hsp-control-volume");
    this.hspCurrentAudioVolume = volumeControl.value;
  }

  // Use default images if none are provided
  preprocessUIImages(uiImages){

    // TODO: Change these links to the CDN links once that is configured
    let emptyImageURL = holostreamConfiguration.imageLocations["emptyImageURL"];
    let standardImageSize = 18;


    let imageKeys = Object.keys(uiImages);

    let standardImageKeys = ["playButton", "pauseButton", "optionsButton", "enterFullscreen", "exitFullscreen", "audioDisabled", "audioEnabled"];
    standardImageKeys.forEach(imageKey => {
      if (!imageKeys.includes(imageKey)){
        uiImages[imageKey] = {
                source: holostreamConfiguration.imageLocations[imageKey],
                width: 18,
                height: 18
            };
      }
      else if (!uiImages[imageKey].source){
        uiImages[imageKey].source = emptyImageURL;
        uiImages[imageKey].width = 0;
        uiImages[imageKey].height = 0;
      }

    });
	
    if (!imageKeys.includes("bufferingSymbol")){
      uiImages["bufferingSymbol"] = {
            source: holostreamConfiguration.imageLocations["bufferingSymbol"],
            width: 150,
            height: 150
          };
    }
    else if (!uiImages["bufferingSymbol"].source){
      uiImages["bufferingSymbol"].source = emptyImageURL;
      uiImages["bufferingSymbol"].width = 0;
      uiImages["bufferingSymbol"].height = 0;
    }

    if (!imageKeys.includes("jumboPlayButton")){
      uiImages["jumboPlayButton"] = {
            source: holostreamConfiguration.imageLocations["jumboPlayButton"],
            width: 150,
            height: 150
          };
    }
    else if (!uiImages["jumboPlayButton"].source){
      uiImages["jumboPlayButton"].source = emptyImageURL;
      uiImages["jumboPlayButton"].width = 0;
      uiImages["jumboPlayButton"].height = 0;
    }

    return uiImages;
  }

  // Convenience function to set multiple attributes on an element at once
  setAttributes(element, attributes)
  {
    Object.keys(attributes).forEach(attributeKey => {
      element.setAttribute(attributeKey, attributes[attributeKey]);
    });
  }

  // Convenience function to create an element of a given type, parent it
  // to a provided node, and define its class(es)
  createElement(elementType, parentNode, classList)
  {
    let element = document.createElement(elementType);
    element.setAttribute("class", classList);
    parentNode.appendChild(element);

    return element;
  }

  resetLargePlayButtonStatus(){
    this.jumboPlayButton.showedOnce = false;
  }

  showLargePlayButton(){
    if (this.jumboPlayButton.showedOnce === false){
      this.jumboPlayButton.setAttribute("class", "hsp-large-play-container");
      this.jumboPlayButton.playIcon.setAttribute("class", "hsp-play-control hsp-control hsp-large-play-button");
      this.jumboPlayButton.showedOnce = true;
    }
  }

  // Convenience function to initialize an SVG element with
  // the required functions, then parent it to a given node and
  // define its class(es)
  createSVGButton = async function(parentNode, classList, width, height, url)
  {
    const svgNS = "http://www.w3.org/2000/svg";

    // Note: SVGs cannot be dynamically loaded cross-origin; this is enforced by the browser. We can work around
    // this by downloading our remote SVGs, adding some additional text to enforce colour and dimensions, and
    // then creating the dynamic element with the resulting text. 
    let svgResult = await fetch(url).then(async x => await x.text());
    svgResult = svgResult.replace(svgNS, `"${svgNS}" height="${height}" width="${width}"`);
    let footerString = `<use xlink:href="#${url.split("#")[1]}" color="white" style="height:${height}px; width:${width}px"/>\n</svg>`;
    svgResult = svgResult.replace("</svg>", footerString);
    
    let svgElement = document.createElement('div');
    svgElement.innerHTML = svgResult;
    parentNode.appendChild(svgElement);

    this.setAttributes(
      svgElement,
      {
        class: classList,
        role: "presentation",
        focusable: "false",
      }
    );
    
    svgElement.setAttribute("style", `width: ${width}px; height: ${height}px`);

    return svgElement;
  }

  constructLargePlayContainer(parentNode)
  {
    let largePlayContainer = this.createElement(
      "div",
      parentNode,
      "hsp-large-play-container hsp-transparent"
    );

    let svgHeight = this.uiImages["jumboPlayButton"].height;
    let playButton = this.createElement(
      "button",
      largePlayContainer,
      "hsp-play-control hsp-control hsp-large-play-button hsp-large-play-button-inactive"
    );
    playButton.setAttribute("type", "button");
    playButton.setAttribute("width", svgHeight);
    playButton.setAttribute("height", svgHeight);
    playButton.onclick = () => this.updatePlayState(true);

    this.createSVGButton(playButton, 
                        "",
                        svgHeight, 
                        svgHeight,
                        this.uiImages["jumboPlayButton"].source);

    this.jumboPlayButton = largePlayContainer;
    this.jumboPlayButton.playIcon = playButton;
  }

  async constructBufferingContainer(parentNode)
  {
    let bufferingContainer = this.createElement(
      "div",
      parentNode,
      "hsp-buffering-container"
    );

    let svgHeight = this.uiImages["bufferingSymbol"].height;

    let bufferingSVG = await this.createSVGButton(bufferingContainer, 
                              "hsp-buffering-icon",
                              svgHeight, 
                              svgHeight,
                              this.uiImages["bufferingSymbol"].source);

    bufferingContainer.setAttribute("width", svgHeight);
    bufferingContainer.setAttribute("height", svgHeight);
    this.bufferingSpinner = bufferingContainer;
    this.bufferingSpinner.bufferingImage = bufferingSVG;
  }

  constructPlayPauseContainer(parentNode)
  {
    let svgHeight = this.uiImages["playButton"].height;
    parentNode.style.height = svgHeight;

    let playButton = this.createElement(
      "button",
      parentNode,
      "hsp-play-control hsp-control"
    );
    playButton.setAttribute("type", "button");
    playButton.onclick = () => this.updatePlayState();

    let playSVG = this.createSVGButton(playButton, 
                      "hsp-control-play-icon hsp-icon",
                      svgHeight, 
                      svgHeight,
                      this.uiImages["playButton"].source);

    let pauseSVG = this.createSVGButton(playButton, 
                      "hsp-hide-element hsp-control-pause-icon hsp-icon",
                      svgHeight, 
                      svgHeight,
                      this.uiImages["pauseButton"].source);
  }

  handleSeek(parentNode)
  {

  }

  constructSeekContainer(parentNode)
  {
    let seekInput = this.createElement(
      "input",
      parentNode,
      "hsp-control-seek hsp-control"
    );

    this.setAttributes(
      seekInput,
      {
        type: "range",
        id:"hsp-control-seek",
        min:"0",
        max:"1",
        step:"0.05",
        value:"0"
      }
    );
    seekInput.oninput = () => {
      this.onSeek(seekInput.value);
      this.updateProgressStyle(seekInput);
    };
    //seekInput.addEventListener("input", () => this.handleSeek);
  }

  constructTimeContainer(parentNode)
  {
    parentNode.innerHTML = "00:00";
    parentNode.setAttribute("id", "hsp-time-container");
  }

  constructAudioContainer(parentNode)
  {
    let svgHeight = this.uiImages["audioEnabled"].height;

    let audioGrid = this.createElement(
      "div",
      parentNode,
      "hsp-audio-grid"
    );

    // Mute button
    let muteGridCell = this.createElement(
      "div", 
      audioGrid, 
      "hsp-audio-grid-cell"
    );

    let muteContainer = this.createElement(
      "div",
      muteGridCell,
      "hsp-control-container"
    );

    muteContainer.style.height = svgHeight;

    let muteButton = this.createElement(
      "button",
      muteContainer,
      "hsp-audio-enable-control hsp-control"
    );
    muteButton.setAttribute("type", "button");
    

    this.createSVGButton(muteButton, 
                      "hsp-control-audio-enabled-icon hsp-icon",
                      svgHeight, 
                      svgHeight,
                      this.uiImages["audioEnabled"].source);

    this.createSVGButton(muteButton, 
                        "hsp-hide-element hsp-control-audio-disabled-icon hsp-icon",
                        svgHeight, 
                        svgHeight,
                        this.uiImages["audioDisabled"].source);

    // Volume slider area
    let volumeGridCell = this.createElement(
      "div", 
      audioGrid, 
      "hsp-audio-grid-cell"
    );

    let volumeContainer = this.createElement(
      "div",
      volumeGridCell,
      "hsp-control-volume-container hsp-control-container"
    );

    let volumeSlider = this.createElement(
      "input",
      volumeContainer,
      "hsp-control-volume hsp-control"
    );
    this.setAttributes(
      volumeSlider,
      {
        type: "range",
        min: 0,
        max: 1,
        step: 0.05,
        value: .5,
        autocomplete: "off",
        role: "slider",
        style: "--value:55.00000000000001%;",
        aria: "55.0%",
              id: "hsp-control-volume"
      }
    );

    muteButton.onclick = () => {
      this.toggleAudioEnabled();
      this.updateProgressStyle(volumeSlider);
    };

    volumeSlider.oninput = () => {
      let volumeControl = document.getElementsByClassName("hsp-control-volume");
      if (volumeControl !== undefined){
        volumeControl = volumeControl[0];
        this.videoWorker.videoPlayer.volume = volumeControl.value;
      }
      this.updateProgressStyle(volumeSlider);
    };
    this.updateProgressStyle(volumeSlider);
  }
  
  onClickOrbitToggle(){
    if(this.orbitControls){
      this.orbitControls.autoRotate = !this.orbitControls.autoRotate;
      this.orbitControls.update();
    }
  }

  onClickLightingToggle(){
    const checked = document.getElementById("lighting-toggle-button").checked;
    this.holoStream.setLightingEnabled(checked);
  }

  toggleOptionsModal(){
    let modal = document.getElementById("hsp-options-modal");
    if (modal.style.visibility == "hidden") {
      modal.style.visibility = "visible";
    } else {
      modal.style.visibility = "hidden";
    }
  }

  constructOptionsContainer(parentNode){
    let optionsButton = this.createElement(
      "button",
      parentNode,
      "hsp-options-control hsp-control"
    );
    optionsButton.id = "hsp-options-button";
    let svgHeight = this.uiImages["optionsButton"].height;

    optionsButton.setAttribute("type", "button");
    optionsButton.onclick = () => {
      this.toggleOptionsModal();
    };

    this.createSVGButton(optionsButton, 
      "hsp-control-options-icon hsp-icon",
      svgHeight, 
      svgHeight,
      this.uiImages["optionsButton"].source);

    parentNode.style.height = svgHeight + "px";
    parentNode.style.marginBottom = "10px";

    this.constructOptionsModal();
  }

  constructOptionsItem(optionsList, labelText, itemID, inputID, toggleFunction, checked){
    var controlItem = document.createElement("li");
    controlItem.id = itemID;
    optionsList.appendChild(controlItem);

    var controlContainer = document.createElement("div");
    controlContainer.classList.add("hsp-options-item");
  
    controlItem.appendChild(controlContainer);

    var controlInput = document.createElement("input");
    controlInput.type = "checkbox";
    controlInput.id = inputID;
    controlInput.checked = checked;
    controlInput.onclick = toggleFunction;

    var controlInputLabel = document.createElement("label");
    controlInputLabel.id = "options-toggle-button-label";
    controlInputLabel.for = "options-toggle-button";
    controlInputLabel.innerHTML = labelText;

    controlContainer.appendChild(controlInputLabel);
    controlContainer.appendChild(controlInput);
  }

  constructOptionsModal(){
    var modal = document.createElement("div");
    modal.id = "hsp-options-modal";
    modal.style.visibility = "hidden";

    document.getElementById("hsp-options-button").parentElement.appendChild(modal);

    var optionsList = document.createElement("ul");
    optionsList.id = "hsp-options-list";
    modal.appendChild(optionsList);

    this.constructOptionsItem(optionsList, "Lighting", "hsp-lighting-control", "lighting-toggle-button", this.onClickLightingToggle, this.lightingEnabledStartup);
  }

  constructFullscreenContainer(parentNode)
  {
    let fullscreenButton = this.createElement(
      "button",
      parentNode,
      "hsp-fullscreen-control hsp-control"
    );

    let svgHeight = this.uiImages["enterFullscreen"].height;

    fullscreenButton.setAttribute("type", "button");
    fullscreenButton.onclick = () => this.toggleFullscreen('.hsp-player');

    let fullscreenShowSVG = this.createSVGButton(fullscreenButton, 
                          "hsp-control-maximizeFullScreen-icon hsp-icon",
                          svgHeight, 
                          svgHeight,
                          this.uiImages["enterFullscreen"].source);
    let fullscreenHideSVG = this.createSVGButton(fullscreenButton, 
                          "hsp-hide-element hsp-control-minimizeFullScreen-icon hsp-icon",
                          svgHeight, 
                          svgHeight,
                          this.uiImages["exitFullscreen"].source);

    parentNode.style.height = svgHeight + "px";
  }

  constructDefaultUI(playerParentNode)
  {

    let hspControlsParent = document.createElement('div');
    hspControlsParent.classList.add("hsp-controls-container");

    playerParentNode.appendChild(hspControlsParent);

    let hspControlsGrid = document.createElement('div');
    hspControlsGrid.classList.add("hsp-controls-grid");

    hspControlsParent.appendChild(hspControlsGrid);

    // Create control containers and initialize with common values
    let controlContainers = {hspPlayPauseArea: undefined, 
                hspSeekArea: undefined, 
                hspTimeArea: undefined, 
                hspOptionsArea: undefined,
                hspAudioArea: undefined,
                hspFullscreenArea: undefined};
    
    Object.keys(controlContainers).forEach(controlTypeKey => {
      controlContainers[controlTypeKey] = this.createElement(
        "div", 
        hspControlsGrid,
        "hsp-control-container"
      );
    });

    // Add unique classes where necessary
    controlContainers["hspSeekArea"].classList.add("hsp-control-seek-container");
    controlContainers["hspTimeArea"].classList.add("hsp-time-container");

    this.constructPlayPauseContainer(controlContainers["hspPlayPauseArea"]);
    this.constructSeekContainer(controlContainers["hspSeekArea"]);
    this.constructTimeContainer(controlContainers["hspTimeArea"]);
    this.constructOptionsContainer(controlContainers["hspOptionsArea"]);
    this.constructAudioContainer(controlContainers["hspAudioArea"]);
    this.constructFullscreenContainer(controlContainers["hspFullscreenArea"]);

    this.constructLargePlayContainer(playerParentNode);
    this.constructBufferingContainer(playerParentNode);
  }

  /* because chrome doesn't support styling input sliders we use a javascript to style the background of the slider */
  updateProgressStyle(sliderElement) 
  {

      const value = (sliderElement.value-sliderElement.min)/(sliderElement.max-sliderElement.min)*100;
      sliderElement.style.background = 'linear-gradient(to right, #aeaeae 0%, #aeaeae ' + value + '%, #5d5a5e ' + value + '%, #5d5a5e 100%)';
  }

  toggleIconVisibility(elementToShow, elementToHide)
  {
      elementToHide.classList.remove("hsp-display-element");
      elementToHide.classList.add('hsp-hide-element');
      elementToShow.classList.remove('hsp-hide-element');
      elementToShow.classList.add('hsp-display-element');
  }

  convertSecondsToMinsSecondsString(totalClipDuration, seconds)
  {
      var countDown = Math.abs(seconds-totalClipDuration);
      var minutes = Math.floor(countDown / 60);
      return "-"+minutes.toString().padStart(2,"0") + ":" + Math.floor((countDown % 60)).toString().padStart(2,"0");
  }

  toggleFullscreen(fullScreenElement)
  {
    /* cross browser toggle full screen command */
    const _toggleFullscreen = function (elem) {
        elem = elem || document.documentElement;

        if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } 
            else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } 
            else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } 
            else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } 
        else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } 
            else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } 
            else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } 
            else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };

    const maximizeFullScreenIcon = document.querySelector('.hsp-control-maximizeFullScreen-icon');
      const minimizeFullScreenIcon = document.querySelector('.hsp-control-minimizeFullScreen-icon');

      /* check to see if we are in full screen */
      const maximizeFullScreenIconStyle = getComputedStyle(maximizeFullScreenIcon);
      /* if the maximize icon is visible, then we are not in full screen mode */
      if (maximizeFullScreenIconStyle['visibility'] === "visible") {
          this.toggleIconVisibility(minimizeFullScreenIcon, maximizeFullScreenIcon);
      } 
      else {
          this.toggleIconVisibility(maximizeFullScreenIcon, minimizeFullScreenIcon);
      }
      let fsElem = document.querySelector(fullScreenElement);
      _toggleFullscreen(fsElem); /* this size change is asynchronous so you must use a call back on the toggle fullscreen function if you depend on knowing the new element dimensions after the resize */
  }

  toggleAudioEnabled()
  {
    const audioEnabledIcon = document.querySelector('.hsp-control-audio-enabled-icon');
      const audioDisabledIcon = document.querySelector('.hsp-control-audio-disabled-icon');
      const volumeControl = document.getElementById("hsp-control-volume");
      const videoPlayer = document.getElementById("videoPlayer");

      const audioEnabledStyle = getComputedStyle(audioEnabledIcon);
      if (audioEnabledStyle['visibility'] == "visible") {
          this.hspCurrentAudioVolume = volumeControl.value;
          videoPlayer.volume = 0;
          volumeControl.value = 0;
          this.toggleIconVisibility(audioDisabledIcon, audioEnabledIcon);
      } 
      else {
          videoPlayer.volume = this.hspCurrentAudioVolume;
          volumeControl.value = this.hspCurrentAudioVolume;
          this.toggleIconVisibility(audioEnabledIcon, audioDisabledIcon);
      }
  }

  isReady()
  {
    return this.uiLoaded;
  }

  initializeLoadedSegmentDisplay(segmentCount)
  {
    // Destroy old segment rectangles
    this.loadedSegments.forEach(segment => {
      if (this.ui !== undefined && this.ui.displayObjects !== undefined){
        let indexToRemove = this.ui.displayObjects.indexOf(segment);
        if (indexToRemove >= 0){
          this.ui.displayObjects.splice(indexToRemove, 1);
        }
      }
    });

    this.loadedSegments = [];
    let segmentWidth = this.playbackBarWidth / segmentCount;
    for (let i = 0; i < segmentCount; i++){
      // TODO: Set some CSS for a linear gradient or whatever
      // i * segmentWidth - 1, 0, segmentWidth + 1);
      
      this.loadedSegments.push("");
    }

  }

  updateLoadedSegmentDisplay(segmentsDownloaded, segmentsTotal)
  {

    // TODO: Handle buffered segments in here


    // Make this colour the bits of the linear gradient
    for (let i = 0; i < segmentsTotal; i++)
    {
      if (this.loadedSegments[i] !== undefined)
      {
        if (i <= segmentsDownloaded)
        {
          // this.loadedSegments[i].color = this.bufferedColor;
        } 
        else 
        {
          // this.loadedSegments[i].color = this.unbufferedColor;
        }
      }
      if (this.splashLoadedSegments[i] !== undefined)
      {
        if (i <= segmentsDownloaded)
        {
          // this.splashLoadedSegments[i].color = this.bufferedColor;
        } 
        else 
        {
          // this.splashLoadedSegments[i].color = this.unbufferedColor;
        }
      }
    }
  }

  updatePlayState(playState)
  {
    if (this.jumboPlayButton){
      this.jumboPlayButton.showedOnce = true;
      this.jumboPlayButton.classList.add("hsp-transparent");
      this.jumboPlayButton.playIcon.classList.add("hsp-large-play-button-inactive");
    }

    const playIcon = document.querySelector('.hsp-control-play-icon');
      const pauseIcon = document.querySelector('.hsp-control-pause-icon');

      const playStyle = getComputedStyle(playIcon);
      this.playing = playStyle['visibility'] === "visible";

    if (this.onPlay != 0){
      this.onPlay(this.playing);
    }

    if (this.playing){
      // Handle the case that we are attempting to autoplay, but the user has 
      // not yet interacted with the document.
      try{
        this.toggleIconVisibility(pauseIcon, playIcon);	
      }
      catch(err){
        this.playing = false;
      }
    }
    else{
      this.toggleIconVisibility(playIcon, pauseIcon);
    }
  }

  updateBufferPercentage(percentage){
    if (percentage < 0){
      percentage = 0;
    }
    else if (percentage > 1){
      percentage = 1;
    }

    return;

    /*let bufferedX = -(this.playbackBarWidth - (this.playbackBarWidth * (percentage))) / 2;
    this.bufferedArea.x = bufferedX;
    this.bufferedArea.width = this.playbackBarWidth * percentage;

    this.bufferedPercentage = percentage;*/
  }

  updatePlaybackPercentage(percentage, currentTime){
    if (percentage < 0){
      percentage = 0;
    }
    else if (percentage > 1){
      percentage = 1;
    }

    let lerp = function(a, b, t){
      return a * t + (1-t) * b;
    };

    let widthHalf = this.playbackBarWidth / 2;

    if (this.playbackHead){
      this.playbackHead.x = lerp(widthHalf, -widthHalf, percentage);
    }
    if (this.playbackTimer && (currentTime !== undefined)){
      this.playbackTimer.text = new Date(currentTime * 1000).toISOString().substr(11, 8);
    }

    this.playbackPercentage = percentage;
  }

  update(videoPlayerID, seekSliderID, timeFieldID)
  {
    if (this.bufferingSpinner && this.bufferingSpinner.bufferingImage){
      if (this.isBuffering){
        this.bufferingSpinner.bufferingImage.setAttribute("class", "hsp-buffering-icon");
      }
      else {
        this.bufferingSpinner.bufferingImage.setAttribute("class", "hsp-buffering-icon hsp-transparent");
      }
    }

    if (!videoPlayerID){
      videoPlayerID = "videoPlayer";
    }

    if (!seekSliderID){
      seekSliderID = "hsp-control-seek";
    }

    if (!timeFieldID){
      timeFieldID = "hsp-time-container";
    }

    let videoPlayer = document.getElementById(videoPlayerID);
      let seekSlider = document.getElementById(seekSliderID);
      let timeField = document.getElementById(timeFieldID);

      seekSlider.max = videoPlayer.duration;
      seekSlider.value = videoPlayer.currentTime;

      if (timeField && videoPlayer){
        
        this.lastUITime = videoPlayer.duration;
        timeField.innerText = this.convertSecondsToMinsSecondsString(videoPlayer.duration, videoPlayer.currentTime);
      }
      
      this.updateProgressStyle(seekSlider);
  }

  destroy(){
    const containerDiv = document.getElementById(this.uiContainerDivID);
    if (containerDiv){
      containerDiv.innerHTML = '';
    }
  }

  setOrbitControls(controls){
    if (controls){
      this.orbitControls = controls;
      this.constructOptionsItem(document.getElementById("hsp-options-list"), "Camera Orbit", "hsp-orbit-control", "orbit-toggle-button", this.onClickOrbitToggle, this.orbitControls.autoRotate);
    }
  }
}

export default PlayerUI;