import OMS from "./oms";

class OMSPlayer {
  constructor(_onOMSPlayerReady) {
    // Events
    this.onOMSPlayerReady = _onOMSPlayerReady;
    this.onPlaybackReady = 0;
    this.onKeyframeReady = 0;
    this.onFrameReady = 0;
    this.omsPlayerReady = false;
    this.omsInstances = [];
    this.omsInstanceCount = 1;

    for (let i = 0; i < this.omsInstanceCount; i++)
    {
        this.omsInstances.push(new OMS());

        this.omsInstances[i].index = i;
        this.omsInstances[i].onLibOMSReady = this.onLibOMSReady;
        this.omsInstances[i].onLibOMSReady = this.omsInstances[i].onLibOMSReady.bind(this);
        this.omsInstances[i].initialize();
    }

    this.activeOMSInstanceIndex = 0;
    this.activeOMSInstance = this.omsInstances[this.activeOMSInstanceIndex];

    this.loadedOMSData = [];

    // liboms
    this.libOMSReady = false;
    this.onLibOMSReady = (this.onLibOMSReady).bind(this);

    // Loaded OMS
    this.omsData = 0;
    this.frameTable = [];
    this.frameCount = 0; 

    // Load Status
    this.omsLoadProgress = 0.0;

    // Playback
    this.activeFrame = -1;
    this.activeSequence = -1;

    this.currentOMSSequence = 0;
    this.firstLoad = false;

    this.loadFrame      = this.loadFrame.bind(this);
    this.loadOMS        = this.loadOMS.bind(this);
    this.clearOMSPlayer = this.clearOMSPlayer.bind(this);
    this.destroy        = this.destroy.bind(this);
  }

  onLibOMSReady()
  {
    this.libOMSReady = true;
    if (!this.omsPlayerReady && this.libOMSReady && this.onOMSPlayerReady != 0)
    {
      this.onOMSPlayerReady();
      this.omsPlayerReady = true;
    }
  }

  clearCachedData()
  {
    for (let i = 0; i < this.loadedOMSData.length; i++)
    {
      this.loadedOMSData[i] = undefined;
    }
  }

  clearOMSPlayer()
  {
    this.clearCachedData();

    for (let i = 0; i < this.omsInstanceCount; i++)
    {
      this.omsInstances[i].clearOMS();
    }

    this.currentOMSSequence = 0;
    this.omsData = 0;
    this.loadedOMSData = [];

    let emptySequence = {
        vertices: [],
        uvs: [],
        bone_indices: [],
        bone_weights: [],
        indices: []
    };

    this.currentOMSSequence = emptySequence;
  }

  loadOMSData(omsData, index)
  {
    let thisPlayer = this;

    if (this.loadedOMSData[index] === undefined){
        let nextInstanceIndex = 0;

        if (!this.firstLoad){
            this.firstLoad = true;
            nextInstanceIndex = this.activeOMSInstanceIndex;
        }

        let nextData = this.omsInstances[nextInstanceIndex].parse(omsData);
        this.loadedOMSData[index] = {
            data: nextData,
            omsInstance: this.omsInstances[nextInstanceIndex],
            omsInstanceIndex: nextInstanceIndex,
            index: index
        };
    }

    for (let i = 0; i < this.loadedOMSData.length; i++){
        if (this.loadedOMSData[i] !== undefined && this.loadedOMSData[i].played && i !== index){
            this.loadedOMSData[i] = undefined;
        }
    }

    this.omsData = this.loadedOMSData[index].data;
    this.activeOMSInstanceIndex = this.loadedOMSData[index].omsInstanceIndex;
    this.activeOMSInstance = this.loadedOMSData[index].omsInstance;
    this.activeSequence = -1;

    this.loadedOMSData[index].played = true;
    thisPlayer.loadOMS(this.omsData);

    this.loadedFrameCount = this.frameCount;
    if (this.onPlaybackReady != 0)
    {
        this.onPlaybackReady();
        this.texturesBuffered = true;
    }

    thisPlayer.omsLoadProgress = 1.0;
  }

  preloadOMSData(omsData, index)
  {
    if (this.loadedOMSData[index] === undefined){
        let nextInstanceIndex = 0;
        
        let nextData = this.omsInstances[nextInstanceIndex].parse(omsData);
        this.loadedOMSData[index] = {
            data: nextData,
            omsInstance: this.omsInstances[nextInstanceIndex],
            omsInstanceIndex: nextInstanceIndex,
            index: index
        };
    }
  }

  loadOMS(omsData)
  {
    this.omsData = omsData;

    this.frameCount = omsData.frame_count;
    this.frameTable = [];

    // Populate frame table.
    for(var i = 0; i < omsData.sequenceTable.length; ++i)
    {
        for (var n = 0; n < omsData.sequenceTable[i].frame_count; ++n)
        {
            var pair = [i, n];
            this.frameTable.push(pair);
        }
    }
  }

  isFrameReady(/*frame*/)
  {
    if (this.omsLoadProgress < 1.0)
    {
        return false;
    }

    return true;

    /*if (frame >= this.loadedFrameCount)
    {
        return false;
    }

    return true;*/
  }

  loadFrame(frame, chunk, forceUpdate)
  {
    if (this.frameTable === undefined || this.frameTable.length === 0){
        if (this.frameTable === undefined){
            console.log("No Frame Table -- undefined");
            return false;
        }
        else{
            if (chunk !== undefined && this.activeSequence !== -1){
                this.activeSequence.parse(chunk);
            }
            else{
                return false;
            }
        }
    }

    var framePair = this.frameTable[frame];
    if (framePair === undefined){
        console.log("No Frame Pair");
        return false;
    }

    if (this.activeSequence != framePair[0] || forceUpdate > 0)
    {
        this.currentOMSSequence = this.activeOMSInstance.parseNext(framePair[0]);

        if (!this.currentOMSSequence){
            return false;
        }

        // Raise New Sequence Event.
        if (this.onKeyframeReady != 0)
        {
            this.onKeyframeReady(this.currentOMSSequence);
        }
    }

    if (this.currentOMSSequence === undefined){
        return false;
    }

    if (this.currentOMSSequence.ssdr_frames === undefined){
        return false;
    }

    // Raise New Frame Event.
    if (this.onFrameReady != 0)
    {
        var matrixArray = this.currentOMSSequence.ssdr_frames[framePair[1]];
        if (matrixArray !== undefined && matrixArray.byteLength !== 0)
        {
            this.onFrameReady(this.currentOMSSequence, matrixArray);    
        }
    }

    this.activeFrame = frame;
    this.activeSequence = framePair[0];
    return true;
  }

  destroy()
  {
    this.clearOMSPlayer(); 

    for (let i = 0; i < this.omsInstanceCount; i++)
    {
        this.omsInstances[i].destroyOMS();
    }
  }
}

export default OMSPlayer;