// Common functions shared by all video workers
class VideoWorkerCommon {
    constructor(/*glContext*/) {}

    // Frame Decoding
    decodeFrameIndex(imageData)
    {
        // Binary data is encoded in the image as 3 rows of 8 4x4 bits. Image data is returned
        // as a single long array, with every 4 elements being (RGBA) for a pixel. This translates
        // to 128 pixels per row and 16 pixels per bit. Each row is offset by 512 pixels. The loops
        // below parse that data.
        let rBinaryString = "";
        for (let i = 0; i < 128; i += 16){
            if (imageData[i] > 128 && imageData[i + 1] > 128 && imageData[i + 2] > 128 &&
                imageData[i + 4] > 128 && imageData[i + 5] > 128 && imageData[i + 6] > 128){
                rBinaryString += "1";
            }
            else{
                rBinaryString += "0";   
            }
        }

        let gBinaryString = "";
        for (let i = 128; i < 256; i += 16){
            if (imageData[i] > 128 && imageData[i + 1] > 128 && imageData[i + 2] > 128 &&
                imageData[i + 4] > 128 && imageData[i + 5] > 128 && imageData[i + 6] > 128){
                gBinaryString += "1";
            }
            else{
                gBinaryString += "0";   
            }
        }

        let bBinaryString = "";
        for (let i = 256; i < 256 + 128; i += 16){
            if (imageData[i] > 128 && imageData[i + 1] > 128 && imageData[i + 2] > 128 &&
                imageData[i + 4] > 128 && imageData[i + 5] > 128 && imageData[i + 6] > 128){
                bBinaryString += "1";
            }
            else{
                bBinaryString += "0";   
            }
        }

        return parseInt(rBinaryString, 2) * 65536 + parseInt(gBinaryString, 2) * 256 + parseInt(bBinaryString, 2);
    }
}

export default VideoWorkerCommon;