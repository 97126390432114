import VideoWorkerCommon from "./VideoWorkerCommon";

class VideoWorkerHLS {
  constructor(glContext) {
    // WebGL Context
    this.gl = glContext;

    // Common functions
    this.videoWorkerCommon = new VideoWorkerCommon();

    // Bind Functions
    this.openVideo                  = this.openVideo.bind(this);
    this.update                     = this.update.bind(this);
    this.isReady                    = this.isReady.bind(this);
    this.play                       = this.play.bind(this);
    this.pause                      = this.pause.bind(this);
    this.seekTo                     = this.seekTo.bind(this);
    this.setTotalSegments           = this.setTotalSegments.bind(this);
    this.getCurrentSegment          = this.getCurrentSegment.bind(this);
    this.getPlaybackPercentage      = this.getPlaybackPercentage.bind(this);
    this.initializeFrameBuffer      = this.initializeFrameBuffer.bind(this);
    this.updateVideoTexture         = this.updateVideoTexture.bind(this);
    this.clearWorker                = this.clearWorker.bind(this);
    this.canPlayFromCurrentTime     = this.canPlayFromCurrentTime.bind(this);
    this.foundAlphaInVideoTexture   = this.foundAlphaInVideoTexture.bind(this);
    this.detectValidHLSTexture      = this.detectValidHLSTexture.bind(this);

    // Internal Events
    this.onVideoPlayerError             = this.onVideoPlayerError.bind(this);
    this.onVideoPlayerCanPlay           = this.onVideoPlayerCanPlay.bind(this);
    this.onVideoPlayerLoadedMetaData    = this.onVideoPlayerLoadedMetaData.bind(this);

    // Playback Management
    this.previousVideoPlayerTime = 0.0;
    this.previousVideoPlayerClock = 0.0;
    this.isPlaying = false;
    this.isSeeking = false;

    // Frame Decoding
    this.decodedFrameIndex = 0;
    this.lastDecodedFrameIndex = 0;
    this.frameBuffer = 0;
    this.frameBufferTexture = 0;

    // Video Management
    this.loadedVideo = false;
    this.videoPlayerCanPlay = false;
    this.videoPlayer = 0;
    this.mimeCodec = "";
    this.duration = 0;
    
    this.videoDataBuffer = [];
    this.lastAddedVideoIndex = 0;
    this.totalSegments = 0;
    this.isBuffering = false;
    this.framesPerSegment = 0;
    this.framesPerSecond = 0;
    
    this.autoPaused = false;
    this.bufferedSegments = [];

    // There is a WebKit bug preventing HLS streaming video from being used as a WebGL texture
    // on iOS 14. Until the issue is resolved, detect iOS version and render to a canvas as
    // an intermediate step.
    //
    // https://bugs.webkit.org/show_bug.cgi?id=218637
    this.canvasPath = false;
    let osVersion = this.detectOSVersion();
    
    // This path does not work on Safari 15.3 for both iOS and macOS
    if (this.shouldUseCanvasPath(osVersion)){
        this.canvasPath = true;

        this.videoCanvas = document.createElement("canvas");
        this.videoCanvas.setAttribute("id", "videoCanvas");
        this.videoCanvas.setAttribute("width", "1024");
        this.videoCanvas.setAttribute("height", "1024");
        this.videoCanvas.setAttribute("style", "position: absolute; top: 0; z-index: -999; display: none;");

        this.videoCanvasContext = this.videoCanvas.getContext('2d');

        document.body.appendChild(this.videoCanvas);
    }

    this.createdVideoPlayer = false;
    this.detectedValidTexture = false;
    this.begunPreventingInvalidHLSTexture = false;
    }

  onVideoPlayerError(err)
  {
    console.log("Video Player Error: ");
    console.log(err);
  }

  onVideoPlayerCanPlay()
  {
    this.videoPlayerCanPlay = true;
    this.isSeeking = false;
  }

  async onVideoPlayerLoadedMetaData()
  {
    // (rbrt) - on iOS the video will not preload until it is played. The code
    // below will call play, which will throw an error if the user has not yet
    // interacted with the page because we are not allowed to autoplay the video.
    // The error is harmless and can be ignored, and the video will then be loaded
    // and the texture will be available to HoloStream. Call pause after in case the
    // user has interacted with the page, so that the video does not unexpectedly autoplay.
    while (!this.videoPlayerCanPlay){
        let waitPromise = new Promise(res => setTimeout(res, 100));
        await waitPromise;
        this.videoPlayer.play().catch(err => {});
        this.videoPlayer.pause();
    }
  }

  clearWorker()
  {
    
  }

  canPlayFromCurrentTime(){
    return this.isReady();
  }

  hasBufferedSegmentForCurrentTime()
  {
    return true;
  }

  openVideo(mimeCodec, duration, videoElementId = 0, hlsURL)
  {
    // Reset state
    this.loadedVideo = false;
    this.videoPlayerCanPlay = false;
    this.mimeCodec = "";
    this.duration = duration; 
    this.sourceBuffer = 0;
    this.videoDataBuffer = [];
    this.sourceBufferReady = false;
    this.lastAddedVideoIndex = 0;
    this.totalSegments = 0; 

    // Check if a videoElementId was provided, if not create one.
    if (this.videoPlayer === 0)
    {
        if (videoElementId === 0)
        {
            this.videoPlayer = document.createElement("video");
            this.videoPlayer.setAttribute("id", "videoPlayer");
            this.videoPlayer.setAttribute("width", "1");
            this.videoPlayer.setAttribute("height", "1");
            this.videoPlayer.setAttribute("style", "z-index: 0");
            this.videoPlayer.setAttribute("controls", null);
            this.videoPlayer.setAttribute("autoplay", true);
            this.videoPlayer.setAttribute("playsinline", true);
            this.videoPlayer.setAttribute("loop", true);
            this.videoPlayer.setAttribute("preload", "auto");
            this.videoPlayer.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(this.videoPlayer);

            this.createdVideoPlayer = true;
        }
        else
        {
            this.videoPlayer = document.getElementById(videoElementId);
        }
    }

    this.mimeCodec = mimeCodec;
    this.duration = duration;

    // Setup Video Player
    this.videoPlayer.addEventListener('error', this.onVideoPlayerError);
    this.videoPlayer.addEventListener('canplay', this.onVideoPlayerCanPlay);
    this.videoPlayer.addEventListener('loadedmetadata', this.onVideoPlayerLoadedMetaData);

    this.videoPlayer.src = hlsURL;
    this.videoPlayer.currentTime = 0.0;
    this.loadedVideo = true;
    this.autoPaused = false;
    this.isPlaying = false;
  }

  // Returns true if alpha is found in a texture, and false otherwise.
  foundAlphaInVideoTexture(){
     // This state seems to be manipulated by ThreeJS so make sure we explicitly set it.
    this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, true);

    this.initializeFrameBuffer();

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.frameBufferTexture);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.videoPlayer);
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);

    let pixel = new Uint8Array(4);
    let x = parseInt(this.videoPlayer.videoWidth / 2);
    let y = parseInt(this.videoPlayer.videoHeight / 2);
    this.gl.readPixels(x, y, 1, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, pixel);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    
    if (pixel[3] === 255){
        return true;
    }

    return false;
  }

  // HACK (rbrt): For dealing with iOS WebKit's intermittent failure to copy a video to a texture.
  detectValidHLSTexture(){
    // Once the video can play, assume that we have not yet seen a valid texture
    if (!this.detectedValidTexture && this.videoPlayerCanPlay){
        if (this.foundAlphaInVideoTexture()){
            this.detectedValidTexture = true;
        }

        // Mark that we have begun detecting an invalid HLS texture
        if (!this.begunPreventingInvalidHLSTexture && !this.detectedValidTexture){
            this.hlsInvalidPreventionFrames = 0;
            this.begunPreventingInvalidHLSTexture = true;
        }
    }

    // Track frames to check how long we've been doing this fix
    if (this.begunPreventingInvalidHLSTexture){
        this.hlsInvalidPreventionFrames++;
    }

    // We need to wait one frame to allow for a texture copy from the video to occur successfully.
    const maxHLSPreventionFrames = 0;
    if (this.hlsInvalidPreventionFrames > maxHLSPreventionFrames && !this.detectedValidTexture){
        if (this.debugEnabled){
            console.log("Recreating HoloStream Texture due to WebKit HLS texture error");
        }

        this.begunPreventingInvalidHLSTexture = false;
        this.hlsInvalidPreventionFrames = 0;
        this.detectedValidTexture = false;

        this.openVideo(this.mimeCodec, this.duration, 0, this.videoPlayer.src);
    }

    return this.detectedValidTexture;
  }

  update = async function()
  {
    if (this.videoPlayer.readyState >= 2){
        this.videoPlayerCanPlay = true;
    }

    if (!this.loadedVideo){
        return;
    }

    if (!this.detectValidHLSTexture()){
        return;
    }

    if (!this.canPlayFromCurrentTime()){
        // Pause if we don't have enough to play
        if (this.isPlaying){
            this.autoPaused = true;
            this.pause();
        }
    }
    else{
        // Unpause if we paused because of not having enough content cached
        if (!this.isPlaying && this.autoPaused && ((this.getCurrentSegment()) in this.omsBuffer)){
            this.autoPaused = false;
            this.play();
        }
    }

    // HLS video can fail to loop in HTML5 video players. This state can be detected by finding
    // that the current time has exceeded the duration of a video which has begun playback. If
    // we enter this state, manually loop the video.
    if (this.isPlaying && (this.videoPlayer.currentTime >= this.videoPlayer.duration)){
        this.videoPlayer.currentTime = 0;
    }

    if (this.omsBuffer && ((this.getCurrentSegment()) in this.omsBuffer)){
        this.isBuffering = false;
    }
    else{
        this.isBuffering = true;
    }
  }

  isReady()
  {
    // Video is loaded and ready to play
    //return this.videoPlayer.readyState >= 3;
    return this.videoPlayer.readyState >= 3 && this.detectedValidTexture;
  }

  play()
  {
    if (this.detectedValidTexture){
        this.isPlaying = true;
        this.videoPlayer.play().catch(err => {});
        return true;
    }
    else{
        if (this.debugEnabled){
            console.log("Cannot play, HoloStream is not ready.");
        }
        return false;
    }
  }

  pause()
  {
    if (this.detectedValidTexture){
        this.isPlaying = false;
        this.videoPlayer.pause();
        return true;
    }
    else{
        if (this.debugEnabled){
            console.log("Cannot pause, HoloStream is not ready.");
        }
        return false;
    }
  }

  seekTo(progress)
  {
    this.videoPlayer.currentTime = this.videoPlayer.duration * progress;
  }

  setTotalSegments(count)
  {
    this.totalSegments = count;
    this.bufferedSegments = [];
    for (let i = 0; i < this.totalSegments; i++){
        this.bufferedSegments.push(false);
    }
  }

  getCurrentSegment()
  {
    return this.getCurrentSegmentForTime(this.videoPlayer.currentTime);
  }

  getCurrentSegmentForTime(time)
  {
    return Math.floor(time / this.videoPlayer.duration * this.totalSegments);
  }

  getPlaybackPercentage()
  {
    if (isNaN(this.videoPlayer.duration)){
        return;
    }
    
    return this.videoPlayer.currentTime / this.duration;
  }

  initializeFrameBuffer(){
    // Make sure our framebuffer is setup correctly.
    if (this.frameBuffer == 0 || this.frameBufferTexture == 0){
        this.frameBuffer = this.gl.createFramebuffer();

        this.frameBufferTexture = this.gl.createTexture();
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.frameBufferTexture);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, new Uint8Array([255, 0, 255, 255]));
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);

        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);
        this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, 
            this.gl.COLOR_ATTACHMENT0,
            this.gl.TEXTURE_2D, 
            this.frameBufferTexture, 
            0);

        // check if you can read from this type of texture.
        var canRead = (this.gl.checkFramebufferStatus(this.gl.FRAMEBUFFER) == this.gl.FRAMEBUFFER_COMPLETE);
        if (!canRead){
            console.error("WebGL Error: framebuffer cannot be read.");
        }

        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    }
  }

  updateVideoTexture(textureObject)
  {

    if (this.omsBuffer !== undefined && (!(this.getCurrentSegment() in this.omsBuffer))){
        if (this.isPlaying){
            this.autoPaused = true;
            this.pause();
        }
        return false;
    }

    let texture = textureObject.texture;

    // This state seems to be manipulated by ThreeJS so make sure we explicitly set it.
    this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, true);

    this.initializeFrameBuffer();

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.frameBufferTexture);

    if (this.canvasPath){
        if (this.videoCanvas.width !== this.videoPlayer.videoWidth || this.videoCanvas.height !== this.videoPlayer.videoHeight){
            this.videoCanvas.width = this.videoPlayer.videoWidth;
            this.videoCanvas.height = this.videoPlayer.videoHeight;
        }

        this.videoCanvasContext.drawImage(this.videoPlayer, 0, 0);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.videoCanvas);
    }
    else {
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.videoPlayer);
    }

    // Read back pixels for frame decoding.
    if (this.frameBuffer != 0){
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);

        // Subtract 100 because binary pixels occupy 96 (3 * 32) pixels, and there are 4
        // more pixels of padding, for a total of 100.
        // NOTE: due to gl.UNPACK_FLIP_Y_WEBGL we read from the top, not the bottom.
        var pixels = new Uint8Array(96 * 4);
        this.gl.readPixels(this.videoPlayer.videoWidth - 100, 2, 96, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, pixels);
        this.decodedFrameIndex = this.videoWorkerCommon.decodeFrameIndex(pixels);

        // HACK: this is a small cost hack that basically uses the playback timer to estimate what the 
        // decoded frame number *should* be. If its too far off it's very likely something is behaving
        // strangely with the browsers video/webgl implementation. We skip any non-sensical frames.
        let estimateFrameNumber = this.videoPlayer.currentTime * this.framesPerSecond;
        if (Math.abs(estimateFrameNumber - this.decodedFrameIndex) < 3){
            this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
            this.gl.copyTexImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 0, 0, this.videoPlayer.videoWidth, this.videoPlayer.videoHeight, 0);
            this.lastDecodedFrameIndex = this.decodedFrameIndex;
        } 
        else {
            this.decodedFrameIndex = this.lastDecodedFrameIndex;
        }

        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    }
  }

  setFrameInformation(framesPerSegment, framesPerSecond)
  {
    this.framesPerSegment = framesPerSegment;
    this.framesPerSecond = framesPerSecond;
  }

  detectOSVersion()
  {
    var ua = navigator.userAgent;
    var uaindex;

    let userOSver = "";
    let userOS = "";
    let browserString = "";

    // determine OS
    // Hack: Always return true when using Safari. M1 Macs have a WebGL regression but we cannot
    // detect Intel vs M1 chips in the UA string (It always reports Intel), so always default to 
    // this path when the browser is Safari.
    if (ua.match(/Safari/i)){
        browserString = "Safari";
    }

    if (ua.match(/iPad/i) || ua.match(/iPhone/i)){
        userOS = 'iOS';
        uaindex = ua.indexOf( 'OS ' );
    }

    // determine version
    if (userOS === 'iOS'  &&  uaindex > -1){
        userOSver = ua.substr( uaindex + 3, 4 ).replace( '_', '.' );
    }
    else{
        // Attempt to parse version out of UA string with regex. iOS 15.3 and Safari 15.3 both have changed
        // how UA strings are reported and so the version is no longer correctly parsed.
        const versionRegex = /\/([0-9]+\.[0-9]) Safari\//;
        const versionMatch = ua.match(versionRegex);
        if (versionMatch && versionMatch.length === 2){
            userOSver = versionMatch[1];
        }
        else{
            userOSver = 'unknown';    
        }
    }

    return userOS + userOSver + browserString;
  }

  shouldUseCanvasPath(osVersion){
    const splitVersion = osVersion.replace("iOS", "")
                                  .replace("macOS", "")
                                  .replace("Safari", "")
                                  .split(".");
    const majorVersion = parseInt(splitVersion[0]);
    const minorVersion = parseInt(splitVersion[1]);

    // As of 15.3, macOS and iOS Safari do not work with the canvas path.
    if ((majorVersion === 15 && minorVersion >= 2) || majorVersion > 15){
        return false;
    }

    return osVersion.includes("iOS") && osVersion.includes("14.") || osVersion.includes("Safari");
  }

  destroy(){
    if (this.createdVideoPlayer){
        this.videoPlayer.parentNode.removeChild(this.videoPlayer);
        this.videoPlayer = 0;
    }

    if (this.canvasPath){
        this.videoCanvas.parentNode.removeChild(this.videoCanvas);
        this.videoCanvas = 0;   
    }

    this.clearWorker();
  }
}

export default VideoWorkerHLS;