export default {
  config: "development",
  imageLocations: {
    emptyImageURL: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-blank.svg#plyr-blank`,
    playButton: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-play.svg#plyr-play`,
    pauseButton: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-pause.svg#plyr-pause`,
    optionsButton: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-options.svg#plyr-options`,
    bufferingSymbol: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-progress.svg#plyr-progress`,
    jumboPlayButton: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-large-play.svg#plyr-large-play`,
    enterFullscreen:
      `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-enter-fullscreen.svg#plyr-enter-fullscreen`,
    exitFullscreen:
      `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-exit-fullscreen.svg#plyr-exit-fullscreen`,
    audioDisabled:
      `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-audio-disabled.svg#plyr-audio-disabled`,
    audioEnabled: `${HOLOSUITE_RESOURCE_LOCATION}/img/hsp-controls/plyr-audio-enabled.svg#plyr-audio-enabled`,
    holostreamLogo: `${HOLOSUITE_RESOURCE_LOCATION}/img/HoloStream.svg`,
  },
  decoderLocation: `${HOLOSUITE_RESOURCE_LOCATION}/js/Decoder.js`,
};
